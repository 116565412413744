import React from "react";
import Routings from "../routings/Routings";

function App() {
  return (
    <div className="font-[Nunito]">
      <Routings />
    </div>
  );
}

export default App;
