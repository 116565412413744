import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo-300x51.png";
import image7 from "../../images/Component-1.png";
import timona from "../../images/Timona.jpg";
import godfrey from "../../images/Godfrey.jpg";
import brian from "../../images/Brian.jpg";
import Partners from "../partners/Partners";
import steve from "../../images/steve.png";
import diana from "../../images/diana.jpeg";
import emmanuel from "../../images/emmanuel.jpeg";

function About() {
  const [toggle, setToggle] = useState(false);

  const handleClick = () => {
    setToggle(!toggle);
  };

  return (
    <div>
      <div className="homepage bg-aboutImage bg-cover py-10 px-32 h-[380px] xxs:py-4 xxs:px-6 xxs:h-[60px] xsm:h-[70px]  xsm:py-6 xsm:px-8 md:py-3 lg:pt-6  lg:px-16 xl:px-28  lg:pt-8 lg:h-[300px] xl:h-[320px] 2xl:h-[380px]">
        <div className="sectOne flex xxs:justify-between">
          {!toggle ? (
            <Link to="/">
              <img
                src={logo}
                alt="skoolpesa logo"
                className="cursor-pointer text-[1.3rem] xxs:w-[10rem] md:w-[9rem] lg:w-[11rem] xl:w-[12rem] 2xl:w-[13rem] "
              ></img>
            </Link>
          ) : (
            <></>
          )}
          <div className="navbar flex justify-between ml-[5rem] text-[1.1rem] w-full xxs:hidden md:flex md:text-[0.4rem] md:ml-6 lg:ml-[2rem] lg:text-[0.5rem] xl:text-[0.7rem] 2xl:text-[1rem] ">
            <div className="navigation text-white mt-[1rem] md:flex">
              <Link
                to="/"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Home
              </Link>
              <Link
                to="/about-us"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                About us
              </Link>
              <Link
                to="/services"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Services
              </Link>
              <Link
                to="/why-skool-pesa"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Why Skool Pesa
              </Link>
              <Link
                to="/faqs"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                FAQs
              </Link>
              <Link
                to="/contact"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Contact Us
              </Link>
            </div>
            <Link
              to="/sign-up"
              className="my-auto text-white text-[1rem] bg-[#162C4A] rounded-lg px-9 py-4 hover:bg-white hover:text-[#162C4A] hover:border-1 hover:border-[#162C4A] md:text-[0.4rem] md:px-3 md:py-1 md:rounded-[3px] md:my-auto lg:text-[0.4rem] xl:text-[0.7rem] 2xl:text-[1rem] 2xl:px-6 2xl:py-2 2xl:rounded-lg"
            >
              Sign Up Now
            </Link>
          </div>

          <div className="flex">
            {toggle ? (
              <div className="z-50 md:hidden xl:hidden 2xl:hidden bg-[#162C4A] xxs:w-[15rem] xsm:w-[17rem] sm:w-[19rem] grid sm:p-3 xxs:h-[24rem] sm:h-[24rem] xxs:ml-[-1rem] xsm:ml-[rem] sm:ml-[rem] xxs:text-white sm:text-white xxs:pb-24 xxs:p-2 sm:pb-28 ">
                <Link to="/" className="hover:text-[#162C4A]">
                  Home
                </Link>
                <Link to="/about-us" className="hover:text-[#162C4A]">
                  About us
                </Link>
                <Link to="/services" className="hover:text-[#162C4A]">
                  Services
                </Link>
                <Link to="/why-skool-pesa" className="hover:text-[#162C4A]">
                  Why Skool Pesa
                </Link>
                <Link to="/faqs" className="hover:text-[#162C4A]">
                  FAQs
                </Link>
                <Link to="/contact" className="hover:text-[#162C4A]">
                  Contact Us
                </Link>
                <Link to="/sign-up" className="hover:text-[#162C4A]">
                  Signup
                </Link>
                {/* <input placeholder="Search" className="rounded-[20rem] px-3" /> */}
              </div>
            ) : (
              <></>
            )}
          </div>
          {!toggle ? (
            <i
              className="fa fa-bars text-[#162C4A] border border-blue-400 py-1 px-1 rounded-sm bg-blue-400 xxs:visible xxs:text-white md:hidden xl:hidden 2xl:hidden h-6"
              onClick={handleClick}
            ></i>
          ) : (
            <i
              className="fa fa-times text-[#162C4A] border border-[#162C4A] py-1 px-2 rounded-sm bg-[#162C4A] xxs:visible xxs:text-white md:hidden xl:hidden 2xl:hidden h-6"
              onClick={handleClick}
            ></i>
          )}
        </div>
      </div>

      <div className="px-[14rem] w-[100%] flex justify-between pt-[3rem] pb-[8rem] bg-gray-100 xxs:px-3 xxs:block xxs:pt-1 xxs:pb-5 xsm:px-6 lg:flex lg:px-16 lg:py-2 xl:px-[10rem] 2xl:px-[12rem]">
        <p className="text-[2.5rem] leading-[2.5rem] font-semibold my-auto xxs:py-3 lg:px-2 lg:leading-7 2xl:text-[2rem] w-[48%] xxs:w-[100%] xxs:text-[1rem] xsm:text-[1.1rem] xxs:leading-6   lg:text-[1.3rem]  ">
          <span className="text-blue-500">Skool Pesa</span> is a Global Multi
          Market School fees Payments Platform.
        </p>
        <div className="text-[1.3rem] lg:text-[1.2rem] 2xl:text-[1.3rem] w-[48%] xxs:w-[100%] xxs:text-[1rem] ">
          <p className="text-gray-500 leading-[1.8rem] xxs:leading-6">
            As a Canadian Fintech company focused on efficient school
            fees payment locally and cross-border, we provide
            a digital payment gateway for efficient fee payments to institutions
            as desired by both individuals and corporates.
          </p>
          <p className="text-blue-500 font-semibold mt-[0.5rem]">
            Skool Pesa has its headquarters in Edmonton, Canada.
          </p>
        </div>
      </div>

      <div className="px-[14rem] bg-gray-100 py-1 xxs:px-3 xxs:py-0 xsm:px-6 sm:px-8 lg:py-2 xl:px-16 2xl:px-[14rem]">
        <p className="text-center font-bold text-[3.2rem] xxs:text-[1.6rem]">
          The Team
        </p>
        <div className="flex border-b-[2px] pb-5 border-black my-[2rem] xxs:flex-col-reverse lg:flex lg:flex-row lg:px-3">
          <div className="border-l-[2px] pl-[2rem] pt-[2rem] mx-[2rem] border-gray-400 xxs:border-l-0 xxs:mx-0 xxs:pt-0 xxs:pl-0 lg:border-l-[2px] lg:pl-4 lg:pt-2">
            <p className="text-[3rem] leading-[3rem] font-semibold mb-[2.5rem] lg:text-[1.6rem] lg:leading-9 xxs:mb-0 xxs:text-[1.3rem] xxs:my-0 xsm:text-[1.4rem]  ">
              Timona Jarha
            </p>
            <p className="text-blue-600 text-[1.3rem] mb-[1rem] xxs:mb-[-0.5rem] lg:mb-1">
              Founder & CEO
            </p>

            <p className="text-[1.1rem] my-[1rem] text-gray-500 lg:text-[1rem] xxs:my-2 xxs:text-[0.9rem] xxs:mt-0 xsm:text-[1rem] ">
              Timona is a Senior Executive with international experience and a
              passion for Fintech. He has worked in various capacities in North
              America and East Africa. He is a visionary businessman with an eye
              for opportunity. Before moving into business full-time, he worked
              as Director of Operations for a $40 million public-private
              partnership Malaria prevention project in Tanzania with a Canadian
              NGO where they built a network of over 7000 retailers for the
              distribution of treated mosquito nets. This was the first project
              in Africa to introduce the eVoucher platform as a Public Health
              intervention at scale. Timona has also worked as a Public Health
              professional in Canada and US.
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 xxs:my-2 xxs:text-[0.9rem] xsm:text-[1rem] ">
              Throughout his career, Timona has been an entrepreneur at heart
              where he founded several businesses including Shaka Mobile which
              was the first Mobile Virtual Network Operator targeting Africans
              in the diaspora with voice and cross border mobile money transfer
              back in 2009.
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 xxs:my-2 xxs:text-[0.9rem]  xsm:text-[1rem] ">
              He currently serves as the Founder & CEO of Skool Pesa Payments
              Gateway Ltd and Director at African Center for Mobile Financial
              Inclusion (ACMFI). Timona has put together a winning solution to
              address the movement of school fees for Africans in the Diaspora.
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 mb-[3rem] xxs:my-2 xxs:text-[0.9rem] xsm:text-[1rem] ">
              Timona's education includes Master's in Public Human Service and
              Health Administration, Bachelor's in Community Health, Bachelor's
              in Environmental Public Health and Certificate in Public Health.
            </p>
          </div>
          <div className="my-auto lg:ml-[2rem]">
            <img
              alt="Timona Jarha"
              src={timona}
              className="border border-[#162C4A] rounded-lg shadow-lg xxs:w-[8rem] xsm:w-[11rem] sm:w-[12rem] lg:w-[50rem] "
            ></img>
          </div>
        </div>

        <div className="flex border-b-[2px] pb-5 border-black my-[3rem] xxs:my-3 xxs:block lg:flex">
          <div className="my-auto lg:pl-4 lg:mr-[2rem]">
            <img
              src={godfrey}
              alt="Godfrey Kutumela"
              className="border border-[#162C4A] rounded-lg shadow-lg xxs:w-[8rem] xsm:w-[11rem] sm:w-[12rem] lg:w-[50rem] "
            ></img>
          </div>
          <div className="border-l-[2px] pl-[2rem] pt-[2rem] mx-[2rem] border-gray-400 xxs:border-l-0 xxs:mx-0 xxs:pl-0 lg:border-l-[2px] lg:px-3 lg:pt-3">
            <p className="text-[3rem] leading-[3rem] font-semibold mb-[2.5rem] lg:text-[1.6rem] lg:leading-9 xxs:text-[1.3rem] xxs:my-0  xsm:text-[1.4rem] ">
              Godfrey Kutumela
            </p>
            <p className="text-blue-600 text-[1.3rem] mb-[1rem] xxs:my-[-0.5rem] lg:mb-1  ">
              Chief Technical Officer
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 lg:text-[1rem] xxs:text-[0.9rem] xxs:my-2 xxs:mt-0  xsm:text-[1rem] ">
              Godfrey is passionate about using technology and its applications
              in every aspect of humanity to advance the underdeveloped world's
              livelihood and economic conditions. His interests lie in helping
              businesses create and deliver value through the innovative
              application of information and communication technologies with an
              outside-in approach, focusing on consumer and market needs.
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 xxs:text-[0.9rem] xxs:my-2  xsm:text-[1rem] ">
              He has 20 years of Technology Consulting experience specializing
              in Fintech, Cybersecurity, DevSecOps\BizSecOps, Cloud Native and
              General Solution Architect. Extensive background working with
              large-scale, high-profile systems integration and development
              projects that span a customer's organization and experience
              designing robust solutions that bring together multiple platforms.
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 mb-[3rem] xxs:text-[0.9rem] xxs:my-2 xsm:text-[1rem] ">
              Godfrey is also a Co-founder of Alluvial Fintech Solution, A Mifos
              partner for hosted managed banking solutions, a former MTN group
              fintech architecture governance officer overseeing mobile money
              architecture and project implementation in over 16 MTN operating
              markets. A founding member of Mowali, an MTN and Orange wallet
              interoperability companies based in Ivory Coast.
            </p>
          </div>
        </div>

        <div className="flex border-b-[2px] pb-5 border-black my-[3rem] xxs:flex-col-reverse xxs:mb-2 xsm:mb-2 lg:flex-row">
          <div className="border-l-[2px] pl-[2rem] pt-[2rem] mx-[2rem] border-gray-400 xxs:border-l-0 xxs:mx-0 xxs:pl-0 lg:border-l-[2px] lg:pl-3 lg:ml-4">
            <p className="text-[3rem] leading-[3rem] font-semibold mb-[2.5rem] lg:text-[1.6rem] lg:leading-9 xxs:text-[1.3rem] xxs:my-0 xsm:text-[1.4rem]  ">
              Brian Kangetta
            </p>
            <p className="text-blue-600 text-[1.3rem] mb-[1rem] xxs:my-[-0.5rem] lg:mb-1  ">
              Chief Financial Officer
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 lg:text-[1rem] xxs:text-[0.9rem] xxs:my-2  xxs:mt-0 xsm:text-[1rem] ">
              Brian Kangetta is a Certified Public Accountant (CPA) in the
              Public Practice, registered with both the Institute of Certified
              Public Accountants of Kenya (ICPAK) and the National Board of
              Accountants and Auditors (NBAA) in Tanzania. Brian is also a
              Certified Public Secretary (CPS) and possesses both bachelor's and
              master's degrees in Law (LLB and LLM) from the University of
              Nairobi.
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 xxs:text-[0.9rem] xxs:my-2 xsm:text-[1rem] ">
              Brian has experience spanning more than eleven years in offering
              tax compliance and advisory services, with his first four years of
              practice having been spent with KPMG Kenya. He has considerable
              experience in Value Added Tax (VAT) and corporate taxation and
              provides advisory and compliance services to several KMPG clients
              in the mining, oil, and gas, shipping, banking, agricultural,
              telecommunications and manufacturing sectors.
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 mb-[3rem] xxs:text-[0.9rem] xxs:my-2 xsm:text-[1rem] ">
              Brian has also assisted several multinational clients in
              tax-efficient re-structuring, mostly after due-diligence
              assignments. Additionally, he has assisted a myriad of KPMG
              clients in resolving tax audit issues raised under audits
              conducted by both Kenya and Tanzania Revenue Authorities, thereby
              creating beneficial working relationships with the KRA and TRA.
            </p>
          </div>
          <div className="my-auto lg:ml-[2rem] ">
            <img
              src={brian}
              alt="Brian Kangetta"
              className="border border-[#162C4A] rounded-lg shadow-lg xxs:w-[8rem] xsm:w-[11rem] sm:w-[12rem]  lg:w-[50rem] "
            ></img>
          </div>
        </div>
        <div className="flex border-b-[2px] pb-5 border-black my-[3rem] xxs:my-3 xxs:block lg:flex">
          <div className="my-auto lg:pl-4 lg:mr-[2rem]">
            <img
              src={steve}
              alt="Stephen Lukanu"
              className="border border-[#162C4A] rounded-lg shadow-lg xxs:w-[8rem] xsm:w-[11rem] sm:w-[12rem] lg:w-[50rem] "
            ></img>
          </div>
          <div className="border-l-[2px] pl-[2rem] pt-[2rem] mx-[2rem] border-gray-400 xxs:border-l-0 xxs:mx-0 xxs:pl-0 lg:border-l-[2px] lg:px-3 lg:pt-3">
            <p className="text-[3rem] leading-[3rem] font-semibold mb-[2.5rem] lg:text-[1.6rem] lg:leading-9 xxs:text-[1.3rem] xxs:my-0  xsm:text-[1.4rem] ">
              Stephen Lukanu
            </p>
            <p className="text-blue-600 text-[1.3rem] mb-[1rem] xxs:my-[-0.5rem] lg:mb-1  ">
              Software Engineering Team Lead
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 lg:text-[1rem] xxs:text-[0.9rem] xxs:my-2 xxs:mt-0  xsm:text-[1rem] ">
              Stephen is enthusiatic about technology and its applications
              in the betterment of humanity through simplified yet robust applicability. His interests lie in helping
              automate daily processes in a bid to smoothen otherwise cumbersome tasks.
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 xxs:text-[0.9rem] xxs:my-2  xsm:text-[1rem] ">
              He has 5 years of experience in modern technology stacks, specializing
              in Cybersecurity, DevSecOps, Systems Architecture and
              User Centric Development. With a specific liking for consultancy and mentorships, Stephen has been able to
              meet, interact and cultivate new and old tech talent alike, building around him an ecosystem of technocrats.
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 mb-[3rem] xxs:text-[0.9rem] xxs:my-2 xsm:text-[1rem] ">
              Stephen has built and helped cultivate young FinTech startups such as Tuinuane Microfinance, which aims at
              offering financial aid to casual workers and farmers in capital access for business development by designing, implementing and
              maintaining real-time Financial Management Systems for cash transfers and credit evaluation. He has also consulted for instituions
              adopting tech in their work flows such as the Kingdom Seekers Church in network designs and securing digital assets, thus a making him crucial asset in
              company team management and leadership in his craft.
            </p>
          </div>
        </div>
        <div className="flex border-b-[2px] pb-5 border-black my-[3rem] xxs:flex-col-reverse xxs:mb-2 xsm:mb-2 lg:flex-row">
          <div className="border-l-[2px] pl-[2rem] pt-[2rem] mx-[2rem] border-gray-400 xxs:border-l-0 xxs:mx-0 xxs:pl-0 lg:border-l-[2px] lg:pl-3 lg:ml-4">
            <p className="text-[3rem] leading-[3rem] font-semibold mb-[2.5rem] lg:text-[1.6rem] lg:leading-9 xxs:text-[1.3rem] xxs:my-0 xsm:text-[1.4rem]  ">
              Diana Auma
            </p>
            <p className="text-blue-600 text-[1.3rem] mb-[1rem] xxs:my-[-0.5rem] lg:mb-1  ">
              Regional Sales Representative, Kenya
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 lg:text-[1rem] xxs:text-[0.9rem] xxs:my-2  xxs:mt-0 xsm:text-[1rem] ">
              Diana Amwayi is an optimistic and enthusiastic sales professional with over 3 years of experience as a sales representative in slow and fast moving products.
              She is skilled at developing the right action plan for each of her client's unique needs and committed to helping them choose the best products.
              Throughout her career, She has earned the trust of several national and international companies and maintained strong client relationships that generate repeat business.
              She attributes her success to her ability to listen to clients and put their needs first.
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 xxs:text-[0.9rem] xxs:my-2 xsm:text-[1rem] ">
              In her free time, Diana enjoys volunteering with health programmes to help children get health education and attending tech events to learn ideas that might impact the world in future.
              You can connect with Diana via email at diana@skoolpesa.com.
            </p>
          </div>
          <div className="my-auto lg:ml-[2rem] ">
            <img
              src={diana}
              alt="Diana Auma"
              className="border border-[#162C4A] rounded-lg shadow-lg xxs:w-[8rem] xsm:w-[11rem] sm:w-[12rem]  lg:w-[50rem] "
            ></img>
          </div>
        </div>
        <div className="flex border-b-[2px] pb-5 border-black my-[3rem] xxs:my-3 xxs:block lg:flex">
          <div className="my-auto lg:pl-4 lg:mr-[2rem]">
            <img
              src={emmanuel}
              alt="Emmanuel Makundi"
              className="border border-[#162C4A] rounded-lg shadow-lg xxs:w-[8rem] xsm:w-[11rem] sm:w-[12rem] lg:w-[50rem] "
            ></img>
          </div>
          <div className="border-l-[2px] pl-[2rem] pt-[2rem] mx-[2rem] border-gray-400 xxs:border-l-0 xxs:mx-0 xxs:pl-0 lg:border-l-[2px] lg:px-3 lg:pt-3">
            <p className="text-[3rem] leading-[3rem] font-semibold mb-[2.5rem] lg:text-[1.6rem] lg:leading-9 xxs:text-[1.3rem] xxs:my-0  xsm:text-[1.4rem] ">
              Emmanuel Makundi
            </p>
            <p className="text-blue-600 text-[1.3rem] mb-[1rem] xxs:my-[-0.5rem] lg:mb-1  ">
              Regional Sales Representative, Tanzania
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 lg:text-[1rem] xxs:text-[0.9rem] xxs:my-2 xxs:mt-0  xsm:text-[1rem] ">
              Emmanuel Makundi is a Public health professional with more than ten years of experience in coordinating community development programmes.
              He was among a team of dedicated individuals who worked with the Tanzania National Voucher Scheme (TNVS), a Multi-million Dollar US Public Private Partnership Malaria Prevention Project.
              The project created a distributing network of Insecticide Treated Mosquito Nets throughout Tanzania.
              This was a unique project to implement due to its wide area coverage and application of technology in ITN distribution.
              He has  also been an employee of other international NGOs such as Plan International, Concern Worldwide and MEDA in various capacities.
            </p>
            <p className="text-[1.1rem] my-[1rem] text-gray-500 xxs:text-[0.9rem] xxs:my-2  xsm:text-[1rem] ">
              Emmanuel is a holder of Masters Degree in Health policy and Management and Bachelor of Arts in Sociology from Muhimbili University of Health and applied Sciences and The University of Dar es Salaam respectively.
            </p>
          </div>
        </div>
      </div>


      <div className="sectFour px-32 py-10 xxs:px-2 xxs:py-3 xsm:px-6 xl:px-40 2xl:px-[16rem]">
        <h1 className="text-center text-[2.3rem] mb-4 xxs:text-[1.3rem] xxs:mb-1 xxs:mb-0 xsm:text-[1.4rem] md:text-[1.5rem] lg:text-[1.6rem] ">
          Corporate Partners
        </h1>
        <p className="text-center text-[1.3rem] text-gray-500 xxs:text-[1rem] md:text-[1.1rem] lg:text-[1.2rem] ">
          The following are our corporate patners
        </p>
        <Partners />
      </div>

      <div className="bg-gray-100 py-[6rem] px-[17rem] flex justify-between xxs:py-5 xxs:px-4 xxs:block xsm:px-6 lg:flex lg:justify-between xl:px-[11rem] 2xl:px-[17rem] lg:px-24  ">
        <img
          src={image7}
          alt="careers"
          className="xxs:px-12 xsm:px-20 sm:px-40 lg:px-0 lg:w-[30%] "
        ></img>
        <div className="my-auto mr-[3rem] xxs:mr-0 xxs:my-3 lg:my-auto 2xl:mr-[3rem]  lg:w-[60%] ">
          <p className="text-[3rem] font-semibold xxs:text-[1.5rem] 2xl:text-[3rem]">
            Careers
          </p>
          <p className="text-[1.3rem] text-gray-600 my-[1.2rem] xxs:my-1 xxs:text-[0.9rem] 2xl:text-[1.3rem] xsm:text-[1rem] ">
            Skool Pesa is looking for top notch Sales and Marketing candidates
            to join the team. Please send your cover letter and CV to
            careers@skoolpesa.com or email to:
          </p>
          <a
            className="text-blue-600 text-[1.3rem]"
            href="mailto: careers@skoolpesa.com"
          >
            careers@skoolpesa.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;
