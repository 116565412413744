import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo-300x51.png";

// import SignupForm from "../signup/SignupForm";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";

const formValidationSchema = Yup.object().shape({
  institution: Yup.string().required("Institution is required"),
  phone: Yup.string().required("Phone number is required"),
  addressLine1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  country: Yup.string()
    .oneOf(["Kenya", "Tanzania"])
    .required("Country is required"),
  province: Yup.string().required("Province is required"),
  postalCode: Yup.string().required("Postal code is required"),
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required"),
});

const formInitialValues = {
  institution: "",
  phone: "",
  addressLine1: "",
  city: "",
  province: "",
  postalCode: "",
  country: "",
  email: "",
  comments: "",
};

function Contact() {
  const [toggle, setToggle] = useState(false);

  const handleClick = () => {
    setToggle(!toggle);
  };

  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const contactType = "place";

  const handleSubmit = async (values) => {
    if (values.country === "Kenya") {
      try {
        const response = await fetch(
          "https://run.outfieldapp.com/api/v2/contacts",
          {
            method: "POST",
            headers: {
              "x-auth-token": "caosL91tmCzXsmyKHGYy",
              "x-user-email": "diana@skoolpesa.com",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              contact: {
                contact_type: contactType,
                name: values.institution,
                phones: [
                  { label: "mobile", value: values.phone, preferred: null },
                ],
                addresses: [
                  {
                    street1: values.addressLine1,
                    city: values.city,
                    region: values.province,
                    postal_code: values.postalCode,
                  },
                ],
                emails: [{ label: null, value: values.email, preferred: null }],
              },
            }),
          }
        );

        const data = await response.json();

        if (data.contact.is_active === true) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            navigate("/");
          }, 5000);
        } else {
          alert(data.error);
        }
      } catch (error) {
        console.error(error);
      } finally {
        // setSubmitting(false);
      }
    } else if (values.country === "Tanzania") {
      try {
        const response = await fetch(
          "https://run.outfieldapp.com/api/v2/contacts",
          {
            method: "POST",
            headers: {
              "x-auth-token": "iM14LvMD8WSRpsrqvLDb",
              "x-user-email": "emmanuel@skoolpesa.com",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              contact: {
                contact_type: contactType,
                name: values.institution,
                phones: [
                  { label: "mobile", value: values.phone, preferred: null },
                ],
                addresses: [
                  {
                    street1: values.addressLine1,
                    city: values.city,
                    region: values.province,
                    postal_code: values.postalCode,
                  },
                ],
                emails: [{ label: null, value: values.email, preferred: null }],
              },
            }),
          }
        );

        const data = await response.json();

        if (data.contact.is_active === true) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            navigate("/");
          }, 5000);
        }
      } catch (error) {
        console.error(error);
      } finally {
        // setSubmitting(false);
      }
    }
  };

  return (
    <div>
      <div className="homepage bg-contactImage bg-cover py-10 px-32 h-[380px] xxs:h-[60px] xsm:h-[70px] xxs:py-4 xxs:px-6  xsm:py-6 xsm:px-8 md:py-3 lg:pt-6  lg:px-16 xl:px-28  lg:pt-8 lg:h-[300px] xl:h-[320px] 2xl:h-[380px]">
        <div className="sectOne flex xxs:justify-between">
          {!toggle ? (
            <Link to="/">
              <img
                src={logo}
                alt="skoolpesa logo"
                className="cursor-pointer text-[1.3rem] xxs:w-[10rem] md:w-[9rem] lg:w-[11rem] xl:w-[12rem] 2xl:w-[13rem] "
              ></img>
            </Link>
          ) : (
            <></>
          )}
          <div className="navbar flex justify-between ml-[5rem] text-[1.1rem] w-full xxs:hidden md:flex md:text-[0.4rem] md:ml-6 lg:ml-[2rem] lg:text-[0.5rem] xl:text-[0.7rem] 2xl:text-[1rem] ">
            <div className="navigation text-white mt-[1rem] md:flex">
              <Link
                to="/"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Home
              </Link>
              <Link
                to="/about-us"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                About us
              </Link>
              <Link
                to="/services"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Services
              </Link>
              <Link
                to="/why-skool-pesa"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Why Skool Pesa
              </Link>
              <Link
                to="/faqs"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                FAQs
              </Link>
              <Link
                to="/contact"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Contact Us
              </Link>
            </div>
            <Link
              to="/sign-up"
              className="my-auto text-white text-[1rem] bg-[#162C4A] rounded-lg px-9 py-4 hover:bg-white hover:text-[#162C4A] hover:border-1 hover:border-[#162C4A] md:text-[0.4rem] md:px-3 md:py-1 md:rounded-[3px] md:my-auto lg:text-[0.4rem] xl:text-[0.7rem] 2xl:text-[1rem] 2xl:px-6 2xl:py-2 2xl:rounded-lg"
            >
              Sign Up Now
            </Link>
          </div>

          <div className="flex ">
            {toggle ? (
              <div className="z-50 md:hidden xl:hidden 2xl:hidden bg-[#162C4A] xxs:w-[15rem] xsm:w-[17rem] sm:w-[19rem] grid sm:p-3 xxs:h-[24rem] sm:h-[24rem] xxs:ml-[-1rem] xsm:ml-[rem] sm:ml-[rem] xxs:text-white sm:text-white xxs:pb-24 xxs:p-2 sm:pb-28 ">
                <Link to="/" className="hover:text-[#162C4A]">
                  Home
                </Link>
                <Link to="/about-us" className="hover:text-[#162C4A]">
                  About us
                </Link>
                <Link to="/services" className="hover:text-[#162C4A]">
                  Services
                </Link>
                <Link to="/why-skool-pesa" className="hover:text-[#162C4A]">
                  Why Skool Pesa
                </Link>
                <Link to="/faqs" className="hover:text-[#162C4A]">
                  FAQs
                </Link>
                <Link to="/contact" className="hover:text-[#162C4A]">
                  Contact Us
                </Link>
                <Link to="/sign-up" className="hover:text-[#162C4A]">
                  Signup
                </Link>
                {/* <input placeholder="Search" className="rounded-[20rem] px-3" /> */}
              </div>
            ) : (
              <></>
            )}
          </div>
          {!toggle ? (
            <i
              className="fa fa-bars text-[#162C4A] border border-blue-400 py-1 px-1 rounded-sm bg-blue-400 xxs:visible xxs:text-white md:hidden xl:hidden 2xl:hidden h-6"
              onClick={handleClick}
            ></i>
          ) : (
            <i
              className="fa fa-times text-[#162C4A] border border-[#162C4A] py-1 px-2 rounded-sm bg-[#162C4A] xxs:visible xxs:text-white md:hidden xl:hidden 2xl:hidden h-6"
              onClick={handleClick}
            ></i>
          )}
        </div>
      </div>

      <div className="px-52 py-16 xxs:px-2 xxs:py-3 xxs:pt-5 sm:px-5 sm:pt-6 md:py-6 2xl:px-52">
        <p className="text-[3rem] font-semibold text-center xxs:text-[1.3rem] sm:text-[1.7rem] 2xl:text-[2.5rem]">
          Intake/Inquiry Form
        </p>
        <p className="text-gray-600 text-center mb-[3rem] xxs:text-[1rem] xxs:mb-3 sm:text-[1.2rem] sm:mb-6 2xl:text-[1.5rem]">
          Enter Your Details to Register
        </p>
        <div className="flex justify-center xxs:block xxs:m-auto md:flex lg:mx-7 2xl:mx-auto">
          <div className="bg-gray-200 w-[46rem] px-auto py-[1rem] rounded-lg mr-[5rem] xxs:w-[100%] xxs:px-auto xxs:py-1 xxs:mr-0 xxs:mx-auto lg:w-[30rem] xl:w-[34rem] xl:mr-0 2xl:w-[40rem] 2xl:mr-0">
            <Formik
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form className="w-[42rem] mx-auto xxs:w-[90%]">
                  <div className="my-[1rem] mt-[2rem] xxs:mt-5">
                    <label htmlFor="institution" className="font-semibold">
                      Name of Educational Institution:
                    </label>{" "}
                    <i
                      class="fa fa-asterisk fa-2xs"
                      style={{ color: "red" }}
                      aria-hidden="true"
                    ></i>
                    <Field
                      name="institution"
                      type="text"
                      className="w-full p-1 rounded-[3px] outline-blue-500"
                    />
                    <p className="text-red-600 text-[14px]">
                      <ErrorMessage name="institution" />
                    </p>
                  </div>
                  {/* <div className="my-[1rem]">
                  <label htmlFor="numberOfStudents" className="font-semibold">
                    Number of Students:
                  </label>{" "}
                  <Field
                    name="numberOfStudents"
                    type="number"
                    className="w-full rounded-[3px] p-1 outline-blue-500"
                  />
                  <p className="text-red-600 text-[13px]">
                    <ErrorMessage
                      name="numberOfStudents"
                      className="text-red-500"
                    />
                  </p>
                </div> */}

                  <div className="my-[1rem]">
                    <label htmlFor="phone" className="font-semibold">
                      Phone:
                    </label>{" "}
                    <i
                      class="fa fa-asterisk fa-2xs"
                      style={{ color: "red" }}
                      aria-hidden="true"
                    ></i>
                    <Field
                      name="phone"
                      type="text"
                      placeholder="+254712345678"
                      className="w-full rounded-[3px] p-1 outline-blue-500"
                    />
                    <p className="text-red-600 text-[13px]">
                      <ErrorMessage name="phone" />
                    </p>
                  </div>

                  <div className="my-[1rem]">
                    <label htmlFor="addressLine1" className="font-semibold">
                      Address:
                    </label>{" "}
                    <i
                      class="fa fa-asterisk fa-2xs"
                      style={{ color: "red" }}
                      aria-hidden="true"
                    ></i>
                    <Field
                      name="addressLine1"
                      type="text"
                      className="w-full rounded-[3px] p-1 outline-blue-500"
                    />
                    <p className="text-red-600 text-[13px]">
                      <ErrorMessage name="addressLine1" />
                    </p>
                  </div>

                  {/* <div className="my-[1rem]">
                  <label htmlFor="addressLine2" className="font-semibold">
                    Address Line 2:
                  </label>
                  <Field
                    name="addressLine2"
                    type="text"
                    className="w-full rounded-[3px] p-1 outline-blue-500"
                  />
                  <p className="text-red-600 text-[13px]">
                    <ErrorMessage name="addressLine2" />
                  </p>
                </div> */}

                  <div className="my-[1rem]">
                    <label htmlFor="city" className="font-semibold">
                      City:
                    </label>{" "}
                    <i
                      class="fa fa-asterisk fa-2xs"
                      style={{ color: "red" }}
                      aria-hidden="true"
                    ></i>
                    <Field
                      name="city"
                      type="text"
                      className="w-full rounded-[3px] p-1 outline-blue-500"
                    />
                    <p className="text-red-600 text-[13px]">
                      <ErrorMessage name="city" />
                    </p>
                  </div>

                  <div className="my-[1rem]">
                    <label htmlFor="province" className="font-semibold">
                      State/Province/Reqion:
                    </label>{" "}
                    <i
                      class="fa fa-asterisk fa-2xs"
                      style={{ color: "red" }}
                      aria-hidden="true"
                    ></i>
                    <Field
                      name="province"
                      type="text"
                      className="w-full rounded-[3px] p-1 outline-blue-500"
                    />
                    <p className="text-red-600 text-[13px]">
                      <ErrorMessage name="province" />
                    </p>
                  </div>

                  <div className="my-[1rem]">
                    <label htmlFor="postalCode" className="font-semibold">
                      ZIP/Postal Code:
                    </label>{" "}
                    <i
                      class="fa fa-asterisk fa-2xs"
                      style={{ color: "red" }}
                      aria-hidden="true"
                    ></i>
                    <Field
                      name="postalCode"
                      type="text"
                      className="w-full rounded-[3px] p-1 outline-blue-500"
                    />
                    <p className="text-red-600 text-[13px]">
                      <ErrorMessage name="postalCode" className="w-[95%]" />
                    </p>
                  </div>

                  <div className="my-[1rem]">
                    <label htmlFor="country" className="font-semibold">
                      Country:
                    </label>{" "}
                    <i
                      class="fa fa-asterisk fa-2xs"
                      style={{ color: "red" }}
                      aria-hidden="true"
                    ></i>
                    <Field
                      as="select"
                      name="country"
                      type="text"
                      className="w-full rounded-[3px] p-1 outline-blue-500 bg-white"
                    >
                      <option value="">Please select your country</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Tanzania">Tanzania</option>
                    </Field>
                    <p className="text-red-600 text-[13px]">
                      <ErrorMessage name="country" className="w-[95%]" />
                    </p>
                  </div>

                  {/* <div className="my-[1rem] w-[100%]">
                  <label htmlFor="firstName" className="font-semibold">
                    Name of Head of Instituion:
                  </label>{" "}
                  <i
                    class="fa fa-asterisk fa-2xs"
                    style={{ color: "red" }}
                    aria-hidden="true"
                  ></i>
                  <div className="flex w-full xxs:block lg:flex lg:justify-between">
                    <div>
                      <Field
                        name="firstName"
                        type="text"
                        className="mr-3 w-[20.5rem] rounded-[3px] p-1 outline-blue-500 xxs:w-[100%] lg:w-[13rem] xl:w-[15rem] "
                      />
                      <p>First</p>
                      <p className="text-red-600 text-[13px]">
                        <ErrorMessage name="firstName" />
                      </p>
                    </div>
                    <div>
                      <Field
                        name="lastName"
                        type="text"
                        className="w-[20.5rem] rounded-[3px] p-1 outline-blue-500 xxs:w-[100%] lg:w-[13rem] xl:w-[15rem]  "
                      />
                      <p>Last</p>
                      <p className="text-red-600 text-[13px]">
                        <ErrorMessage name="lastName" />
                      </p>
                    </div>
                  </div>
                </div> */}

                  <div className="my-[1rem]">
                    <label htmlFor="email" className="font-semibold">
                      Email
                    </label>{" "}
                    <i
                      class="fa fa-asterisk fa-2xs"
                      style={{ color: "red" }}
                      aria-hidden="true"
                    ></i>
                    <Field
                      name="email"
                      type="email"
                      className="w-full rounded-[3px] p-1 outline-blue-500"
                    />
                    <p className="text-red-600 text-[13px]">
                      <ErrorMessage name="email" />
                    </p>
                  </div>

                  <div className="my-[1rem]">
                    <label htmlFor="comments" className="font-semibold">
                      Additional Comments
                    </label>
                    <textarea
                      name="comments"
                      type="text"
                      rows="6"
                      className="w-full h-24 rounded-[3px] p-1 outline-blue-500"
                    />
                    <p className="text-red-600 text-[13px]">
                      <ErrorMessage name="comments" />
                    </p>
                  </div>

                  <button
                    type="submit"
                    className="border border-[#162C4A] py-1 px-2 rounded-[3px] my-2 text-white bg-[#162C4A]"
                  >
                    Submit Here
                  </button>
                  {success && (
                    <div className="text-green-500">
                      Your request has been received. Our agent will reach out
                      shortly.
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>

          <div className="mt-[3rem] xxs:my-5 xxs:px-6 xxs:mx-auto md:pt-10">
            <div className="flex mb-[2rem] xxs:mb-3">
              <svg
                className="mt-[0.4rem]"
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="15"
                viewBox="0 0 21 15"
                fill="none"
              >
                <g clip-path="url(#clip0_1_245)">
                  <path
                    d="M0 3.61597V11.4759L5.502 7.82668L0 3.61597Z"
                    fill="#141414"
                  ></path>
                  <path
                    d="M21 11.4759V3.61597L15.498 7.82668L21 11.4759Z"
                    fill="#141414"
                  ></path>
                  <path
                    d="M10.5 11.6519L6.84374 8.85376L0 13.393V15.0001H21V13.393L14.1563 8.85376L10.5 11.6519Z"
                    fill="#141414"
                  ></path>
                  <path
                    d="M0 1.60714L10.5 9.64285L21 1.60714V0H0V1.60714Z"
                    fill="#141414"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_1_245">
                    <rect width="21" height="15" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
              <div className="ml-[2rem] xxs:ml-3">
                <p className="font-semibold">Email us:</p>
                <p>
                  <a
                    className=" text-[1.3rem]"
                    href="mailto: sales@skoolpesa.com"
                  >
                    sales@skoolpesa.com
                  </a>
                </p>
                <p>
                <a
                  className=" text-[1.3rem]"
                  href="mailto: careers@skoolpesa.com"
                >
                  careers@skoolpesa.com
                </a>
                </p>
                <p>
                <a
                  className=" text-[1.3rem]"
                  href="mailto: support@skoolpesa.com"
                >
                  support@skoolpesa.com
                </a>
                </p>
              </div>
            </div>
            <div className="flex mb-[2rem] xxs:mb-3">
              <svg
                className="mt-[0.4rem]"
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <g clip-path="url(#clip0_1_240)">
                  <path
                    d="M8.05399 12.979C9.93755 14.8488 12.1177 16.6349 12.9736 15.7725C14.1992 14.538 14.9535 13.4639 17.673 15.6327C20.3925 17.8026 18.3155 19.2628 17.1286 20.4591C15.7589 21.8391 10.6286 20.5558 5.54348 15.5092C0.458633 10.4616 -0.863909 5.34136 0.506814 3.96062C1.69413 2.76435 3.13884 0.677084 5.32763 3.38073C7.51748 6.083 6.4492 6.84559 5.22368 8.08043C4.36816 8.94252 6.17013 11.1089 8.05399 12.979ZM10.7852 4.05905C10.7852 4.05905 10.217 3.97028 9.81917 4.36843C9.40964 4.77797 9.39177 5.48347 9.82332 5.91467C10.0783 6.1697 10.438 6.22405 10.438 6.22405C11.139 6.35554 12.1956 6.57817 13.3199 7.70217C14.4439 8.82612 14.6666 9.88265 14.798 10.5841C14.798 10.5841 14.8524 10.9437 15.1074 11.1987C15.5387 11.63 16.2441 11.6124 16.6537 11.2029C17.0515 10.8051 16.963 10.2369 16.963 10.2369C16.7404 8.81691 16.0521 7.33363 14.8703 6.15183C13.6881 4.97003 12.2048 4.28204 10.7852 4.05905ZM16.115 4.90702C18.1758 6.96782 19.0544 9.30666 18.7684 11.2019C18.7684 11.2019 18.6686 11.802 19.0606 12.194C19.5018 12.6352 20.2011 12.6098 20.6141 12.1972C20.8794 11.9318 20.9372 11.4999 20.9372 11.4999C21.1736 9.86211 20.8763 6.56724 17.6654 3.35669C14.4545 0.146082 11.16 -0.151252 9.52214 0.084844C9.52214 0.084844 9.09059 0.142337 8.82489 0.40799C8.41191 0.820968 8.38681 1.5203 8.82797 1.96147C9.22 2.35349 9.82013 2.25364 9.82013 2.25364C11.7154 1.96764 14.0542 2.84628 16.115 4.90702Z"
                    fill="#141414"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_1_240">
                    <rect width="21" height="21" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
              <div className="ml-[2rem] xxs:ml-3">
                <p className="font-semibold">Call us:</p>
                <p>Kenya: +254 741 243 915</p>
                <p>Tanzania: +255 782 444 343</p>
                <p>Canada: +1 403 385 5042</p>
              </div>
            </div>

            <div className="xxs:mt-6">
              <p className="font-semibold mb-[1rem] xxs:mb-2">Reach Out on Social Media</p>
              <div className="flex">

                <p>
                  <a
                    className="ml-[1rem] xxs:ml-5"
                    href="https://www.facebook.com/profile.php?id=100091849208569"
                  >
                    <i class="fa fa-facebook" aria-hidden="true"></i> Facebook
                  </a>
                </p>
              </div>
              <div className="flex">

                <p>
                  <a
                    className="ml-[1rem] xxs:ml-5"
                    href="https://www.instagram.com/skoolpesa/"
                  >
                    <i class="fa fa-instagram" aria-hidden="true"></i> Instagram
                  </a>
                </p>
              </div>
              <div className="flex">
                <p>
                  <a
                    className="ml-[1rem] xxs:ml-5"
                    href="https://twitter.com/PesaSkool85243"
                  >
                    <i class="fa fa-twitter" aria-hidden="true"></i> Twitter
                  </a>
                </p>
              </div>
              <div className="flex">

                <p>
                  <a
                    className="ml-[1rem] xxs:ml-5"
                    href="https://www.linkedin.com/company/skoolpesa/?viewAsMember=true"
                  >
                    <i class="fa fa-linkedin" aria-hidden="true"></i> LinkedIn
                  </a>
                </p>
              </div>
              <div className="flex">

                <p>
                  <a
                    className="ml-[1rem] xxs:ml-5"
                    href="https://www.youtube.com/channel/UCtucKxMdLN3dwYgxIw9R8_w"
                  >
                    <i class="fa fa-youtube-play" aria-hidden="true"></i> YouTube
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
