import React from "react";
import selcom from "../../images/Group-1-150x81.png";
import finserve from "../../images/Group.png";
import mpesa from "../../images/1200px-M-PESA_LOGO-01-1.png";
import { Link } from "react-router-dom";

function Partners() {
  return (
    <div className="partners flex justify-center my-[6rem] xxs:block xxs:my-3 xxs:px-14 xsm:my-7 md:flex lg:px-32 2xl:px-40">
      <Link
        className="mx-4"
        to="https://www.selcom.net/about-us"
        target="_blank"
      >
        <img
          src={selcom}
          alt="Selcom"
          className="mx-[4rem] cursor-pointer xxs:mx-auto xxs:my-3"
        ></img>
      </Link>
      <Link className="mx-4" to="https://www.finserve.africa/" target="_blank">
        <img
          src={finserve}
          alt="Finserve"
          className="mx-[4rem] cursor-pointer xxs:mx-auto xxs:my-3"
        ></img>
      </Link>
      <Link
        className="mx-4"
        to="https://www.safaricom.co.ke/personal/m-pesa"
        target="_blank"
      >
        <img
          src={mpesa}
          alt="Mpesa"
          className="mx-[4rem] cursor-pointer xxs:mx-auto xxs:my-3"
        ></img>
      </Link>
    </div>
  );
}

export default Partners;
