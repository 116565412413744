import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo-300x51.png";
import image1 from "../../images/imgpsh_fullsize_anim-1-1.png";
import Partners from "../partners/Partners";

function Home() {
  const [toggle, setToggle] = useState(false);

  const handleClick = () => {
    setToggle(!toggle);
  };

  return (
    <div className="landing">
      <div className="homepage bg-homeImage bg-cover py-10 px-32 h-[52rem] xxs:py-4 xxs:px-6 xxs:h-[35.5rem] xsm:py-6 xsm:px-8 xsm:h-[37rem] md:py-3 md:h-[15.8rem] lg:pt-6 lg:h-[26.3rem] xl:h-[33rem] lg:px-16 xl:px-28 lg:pt-8">
        <div className="sectOne flex xxs:justify-between">
          {!toggle ? (
            <Link to="/">
              <img
                src={logo}
                alt="skoolpesa logo"
                className="cursor-pointer text-[1.3rem] xxs:w-[10rem] md:w-[9rem] lg:w-[11rem] xl:w-[12rem] 2xl:w-[13rem] "
              ></img>
            </Link>
          ) : (
            <></>
          )}
          <div className="navbar flex justify-between ml-[5rem] text-[1.1rem] w-full xxs:hidden md:flex md:text-[0.4rem] md:ml-6 lg:ml-[2rem] lg:text-[0.5rem] xl:text-[0.7rem] 2xl:text-[1rem] ">
            <div className="navigation text-white mt-[1rem] md:flex">
              <Link
                to="/"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3 active:text-[#162C4A]"
              >
                Home
              </Link>
              <Link
                to="/about-us"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                About us
              </Link>
              <Link
                to="/services"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Services
              </Link>
              <Link
                to="/why-skool-pesa"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Why Skool Pesa
              </Link>
              <Link
                to="/faqs"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                FAQs
              </Link>
              <Link
                to="/contact"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Contact Us
              </Link>
            </div>
            <Link
              to="/sign-up"
              className="my-auto text-white text-[1rem] bg-[#162C4A] rounded-lg px-9 py-4 hover:bg-white hover:text-[#162C4A] hover:border-1 hover:border-[#162C4A] md:text-[0.4rem] md:px-3 md:py-1 md:rounded-[3px] md:my-auto lg:text-[0.4rem] xl:text-[0.7rem] 2xl:text-[1rem] 2xl:px-6 2xl:py-2 2xl:rounded-lg  "
            >
              Sign Up Now
            </Link>
          </div>

          <div className="flex ">
            {toggle ? (
              <div className="md:hidden xl:hidden 2xl:hidden bg-[#162C4A] xxs:w-[15rem] xsm:w-[17rem] sm:w-[19rem] grid sm:p-3 xxs:h-[24rem] sm:h-[24rem] xxs:ml-[-1rem] xsm:ml-[rem] sm:ml-[rem] xxs:text-white sm:text-white xxs:pb-24 xxs:p-2 sm:pb-28 ">
                <Link to="/" className="hover:text-[#162C4A]">
                  Home
                </Link>
                <Link to="/about-us" className="hover:text-[#162C4A]">
                  About us
                </Link>
                <Link to="/services" className="hover:text-[#162C4A]">
                  Services
                </Link>
                <Link to="/why-skool-pesa" className="hover:text-[#162C4A]">
                  Why Skool Pesa
                </Link>
                <Link to="/faqs" className="hover:text-[#162C4A]">
                  FAQs
                </Link>
                <Link to="/contact" className="hover:text-[#162C4A]">
                  Contact Us
                </Link>
                <Link to="/sign-up" className="hover:text-[#162C4A]">
                  Signup
                </Link>
                {/* <input placeholder="Search" className="rounded-[20rem] px-3" /> */}
              </div>
            ) : (
              <></>
            )}
          </div>
          {!toggle ? (
            <i
              className="fa fa-bars text-[#162C4A] border border-blue-400 py-1 px-1 rounded-sm bg-blue-400 xxs:visible xxs:text-white md:hidden xl:hidden 2xl:hidden h-6"
              onClick={handleClick}
            ></i>
          ) : (
            <i
              className="fa fa-times text-[#162C4A] border border-[#162C4A] py-1 px-2 rounded-sm bg-[#162C4A] xxs:visible xxs:text-white md:hidden xl:hidden 2xl:hidden h-6"
              onClick={handleClick}
            ></i>
          )}
        </div>

        {!toggle ? (
          <div className="sectTwo flex justify-between mt-[6rem] xxs:flex-col-reverse xxs:mt-[3rem] md:flex md:flex-row md:justify-between md:mt-[2rem] lg:mt-[3rem] xl:mt-[4rem] 2xl:mt-[5rem]">
            <div className="payments my-auto xxs:text-center md:text-start md:my-auto md:mt-4 lg:my-auto">
              <h1 className="text-[6rem] text-white font-semibold mb-[3rem] leading-none xxs:text-[1.5rem] xxs:text-center xxs:mt-[2rem] xxs:mb-4 xsm:text-[1.3rem] md:text-start lg:text-[2.3rem] 2xl:text-[2.5rem]  ">
                <span id="fees" className="text-[#162C4A]">
                  School Fees{" "}
                </span>{" "}
                <br></br> Payments Gateway
              </h1>
              <Link
                to="/sign-up"
                className="text-white text-[1.5rem] border rounded-lg py-[0.7rem] px-[2.2rem] hover:bg-white hover:text-[#162C4A] xxs:text-[0.9rem] xxs:py-1 xxs:px-3 xxs:rounded-sm xsm:text-[0.6rem] 2xl:text-[1rem]  2xl:rounded-lg 2xl:px-6 2xl:py-2"
              >
                Sign Up Now
              </Link>
            </div>
            <img
              src={image1}
              alt="Mobile app"
              className="mr-[5rem] h-[45.2rem] xxs:h-[20rem] xxs:w-[10rem] xxs:mx-auto md:h-[13rem] md:w-[6.8rem] md:mr-[3rem] lg:h-[22rem] lg:w-[11.3rem] lg:mr-[4rem] xl:h-[28.3rem] xl:w-[15rem] 2xl:h-[26.8rem] 2xl:w-[14rem]"
            ></img>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="sectThree bg-[#f8f2f2] py-10 pt-[5.5rem] px-32 items-center xxs:px-2 xxs:py-3 xsm:px-4 xsm:py-3 md:px-8 md:py-7 lg:px-24 lg:py-10 xl:px-40 xl:py-14 2xl:px-48 2xl:py-26">
        <h1 className="text-center text-[3.6rem] xxs:text-[1.6rem] xxs:text-center xsm:text-[2rem] xsm:text-center xl:text-[2.8rem] 2xl:text-[2.5rem]">
          Features
        </h1>
        <p
          id="pesa"
          className="text-center text-[1.6rem] text-[#666161] xxs:text-[0.9rem] xsm:text-[1.3rem] md:text-[1.4rem] xl:text-[1.9rem] 2xl:text-[1.5rem]"
        >
          Skool Pesa has the following features. Thus:
        </p>
        <div className="features text-[1.5rem] items-center xxs:text-[0.9rem] md:text-[1rem] lg:text-[1.1rem] xl:text-[1.2rem] 2xl:text-[1.4rem] xl:px-20 2xl:px-32 ">
          <div className="send flex justify-center text-center items-center mb-[4rem] mt-[2rem] xxs:block md:flex xxs:mb-9 ">
            <div className=" mb-[2rem] md:mb-0 md:mx-auto">
              <svg
                className="border bg-white shadow-lg h-[10rem] w-[10rem] p-[2rem] rounded-[50%] cursor-pointer mx-auto border-[#f0e6e6] hover:h-[11.5rem] hover:w-[11.5rem] hover:p-[2.3rem] xxs:h-[6rem] xxs:w-[6rem] xxs:p-[1rem] xxs:hover:w-[6.5rem] xxs:hover:h-[6.5rem] xxs:hover:p-[1.2rem]  xsm:h-[8rem] xsm:w-[8rem] xsm:p-[1.2rem] xsm:hover:w-[8.5rem] xsm:hover:h-[8.5rem] xsm:hover:p-[1.4rem] md:h-[5.5rem] md:w-[5.5rem] md:p-[1rem] md:hover:w-[6.5rem] md:hover:h-[6.5rem] md:hover:p-[1.2rem]    lg:h-[7rem] lg:w-[7rem] lg:p-[1.2rem] lg:hover:w-[8rem] lg:hover:h-[8rem] lg:hover:p-[1.4rem]        xl:h-[8rem] xl:w-[8rem] xl:p-[1.2rem] xl:hover:w-[9rem] xl:hover:h-[9rem] xl:hover:p-[1.6rem]          2xl:h-[9rem] 2xl:w-[9rem] 2xl:p-[1.3rem] 2xl:hover:w-[10rem] 2xl:hover:h-[10rem] 2xl:hover:p-[1.5rem]    "
                xmlns="http://www.w3.org/2000/svg"
                width="87"
                height="87"
                viewBox="0 0 87 87"
                fill="none"
              >
                <path
                  d="M79.7764 63.51C79.4073 63.51 79.1173 63.22 79.1173 62.8509V59.2918C79.1173 58.9227 79.4073 58.6327 79.7764 58.6327C80.1455 58.6327 80.4355 58.9227 80.4355 59.2918V62.8509C80.4355 63.22 80.1323 63.51 79.7764 63.51Z"
                  fill="#FFCE56"
                ></path>
                <path
                  d="M70.0086 45.5827C77.1577 45.5827 82.9532 39.7873 82.9532 32.6382C82.9532 25.4891 77.1577 19.6936 70.0086 19.6936C62.8596 19.6936 57.0641 25.4891 57.0641 32.6382C57.0641 39.7873 62.8596 45.5827 70.0086 45.5827Z"
                  fill="#FCB73E"
                ></path>
                <path
                  d="M70.0086 41.6677C74.9955 41.6677 79.0382 37.6251 79.0382 32.6382C79.0382 27.6513 74.9955 23.6086 70.0086 23.6086C65.0217 23.6086 60.9791 27.6513 60.9791 32.6382C60.9791 37.6251 65.0217 41.6677 70.0086 41.6677Z"
                  fill="#FFCE56"
                ></path>
                <path
                  d="M32.2559 45.3455L44.5677 41.2591C45.7013 40.8768 46.9404 41.3778 47.4808 42.4455C48.1531 43.7373 47.5336 45.3323 46.1627 45.8332L25.7704 53.2941C24.8081 53.65 23.7404 53.5578 22.8572 53.0437L14.6581 48.285C14.1309 47.9818 13.5377 47.8237 12.9313 47.8237H10.9936V34.9318H14.0518C14.869 34.9318 15.6731 35.1955 16.3322 35.67L23.1999 40.6264C23.859 41.1009 24.6631 41.3646 25.4804 41.3646H32.124C33.2313 41.3646 34.1277 42.2609 34.1277 43.3682C34.1277 44.4755 33.4818 45.3587 32.3745 45.3587L23.5295 45.3718"
                  fill="#EBB99C"
                ></path>
                <path
                  opacity="0.1"
                  d="M47.3622 42.2477C47.0854 42.6696 46.6899 42.9991 46.1758 43.1968L25.7836 50.6577C24.8213 51.0137 23.7536 50.9214 22.8704 50.4073L14.6845 45.6487C14.1572 45.3455 13.564 45.1873 12.9577 45.1873H10.9936V47.8237H12.9445C13.5509 47.8237 14.144 47.9818 14.6713 48.285L22.8572 53.0437C23.7404 53.5577 24.8081 53.65 25.7704 53.2941L46.1627 45.8332C47.5336 45.3323 48.1531 43.7373 47.4808 42.4455C47.4413 42.3664 47.4018 42.3137 47.3622 42.2477Z"
                  fill="black"
                ></path>
                <path
                  d="M2.63635 33.9827C2.63635 32.8227 3.58544 31.8736 4.74544 31.8736H8.88453C10.0445 31.8736 10.9936 32.8227 10.9936 33.9827V49.3659C10.9936 50.5259 10.0445 51.475 8.88453 51.475H4.74544C3.58544 51.475 2.63635 50.5259 2.63635 49.3659V33.9827Z"
                  fill="#78474E"
                ></path>
                <path
                  opacity="0.1"
                  d="M8.88453 31.8736H8.35726V46.7164C8.35726 47.8764 7.40817 48.8255 6.24817 48.8255H2.63635V49.3527C2.63635 50.5127 3.58544 51.4618 4.74544 51.4618H8.88453C10.0445 51.4618 10.9936 50.5127 10.9936 49.3527V33.9827C10.9936 32.8227 10.0445 31.8736 8.88453 31.8736V31.8736Z"
                  fill="black"
                ></path>
                <path
                  d="M43.4868 26.3768C43.4868 26.825 43.4604 27.26 43.4077 27.6686C42.6036 34.2727 35.6173 36.975 29.3823 36.975C22.7386 36.975 15.2777 33.9168 15.2777 26.3768C15.2777 21.2886 18.6523 15.4755 22.8968 12.2064H22.9627H35.4723C35.5909 12.2064 35.6964 12.18 35.7886 12.1405C40.0595 15.3964 43.4868 21.2623 43.4868 26.3768Z"
                  fill="#D9903B"
                ></path>
                <g opacity="0.25">
                  <path
                    d="M17.9009 27.3655C17.3604 27.3655 16.9122 26.9173 16.9122 26.3768C16.9122 22.33 19.4827 17.2814 23.1736 14.1046C23.5822 13.7486 24.215 13.7882 24.5709 14.21C24.9268 14.6186 24.8872 15.2514 24.4654 15.6073C21.1831 18.4414 18.8895 22.8705 18.8895 26.39C18.8895 26.9173 18.4545 27.3655 17.9009 27.3655V27.3655Z"
                    fill="white"
                  ></path>
                </g>
                <path
                  d="M35.8677 3.07137L33.4554 8.99H25.0455L22.6332 3.07137L24.2809 3.86227L25.9418 3.07137L27.5895 3.86227L29.2504 3.07137L30.8982 3.86227L32.5591 3.07137L34.2068 3.86227L35.8677 3.07137Z"
                  fill="#D9903B"
                ></path>
                <path
                  d="M35.3273 8.99001H23.345C22.5277 8.99001 21.8555 9.6491 21.8555 10.4795V10.8091C21.8555 11.6264 22.5146 12.2986 23.345 12.2986H35.3273C36.1446 12.2986 36.8168 11.6395 36.8168 10.8091V10.4795C36.8168 9.6491 36.1446 8.99001 35.3273 8.99001V8.99001Z"
                  fill="#D8D8D8"
                ></path>
                <path
                  opacity="0.1"
                  d="M35.3272 8.99001H32.6909C33.5081 8.99001 34.1804 9.6491 34.1804 10.4795V10.8091C34.1804 11.6264 33.5213 12.2986 32.6909 12.2986H35.3272C36.1445 12.2986 36.8168 11.6395 36.8168 10.8091V10.4795C36.8168 9.6491 36.1445 8.99001 35.3272 8.99001V8.99001Z"
                  fill="black"
                ></path>
                <path
                  d="M81.49 71.0368H29.1582C26.7064 71.0368 24.7291 73.0141 24.7291 75.4659C24.7291 77.9177 26.7064 79.895 29.1582 79.895H81.49C79.9214 77.1532 79.9214 73.7787 81.49 71.0368Z"
                  fill="#55A6EF"
                ></path>
                <path
                  d="M72.3154 67.0032H42.3927V58.2637C52.2922 56.5368 62.4159 56.5368 72.3154 58.2637V67.0032Z"
                  fill="#3D5670"
                ></path>
                <path
                  d="M81.9777 55.9436L59.1995 46.3737C58.0132 45.8727 56.6818 45.8727 55.5086 46.3737L32.7305 55.9436C31.4255 56.4841 31.4255 58.3296 32.7305 58.87L42.3927 62.93H72.3154L81.9777 58.87C83.2827 58.3296 83.2827 56.4841 81.9777 55.9436Z"
                  fill="#3D5670"
                ></path>
                <path
                  d="M71.0105 76.6787L67.9918 74.4378L64.9732 76.6787V70.8391H71.0105V76.6787Z"
                  fill="#FCB73E"
                ></path>
                <path
                  d="M29.3823 32.4668C33.7504 32.4668 37.2914 28.9258 37.2914 24.5577C37.2914 20.1897 33.7504 16.6486 29.3823 16.6486C25.0142 16.6486 21.4732 20.1897 21.4732 24.5577C21.4732 28.9258 25.0142 32.4668 29.3823 32.4668Z"
                  fill="#FFCE56"
                ></path>
                <path
                  opacity="0.1"
                  d="M76.9554 21.7236C78.2341 23.7273 78.9986 26.1132 78.9986 28.6836C78.9986 35.8414 73.1986 41.6282 66.0541 41.6282C63.4968 41.6282 61.1109 40.8768 59.0941 39.585C61.3877 43.1836 65.4213 45.5827 70.0086 45.5827C77.1663 45.5827 82.9531 39.7827 82.9531 32.6382C82.9531 28.0377 80.5541 24.0173 76.9554 21.7236V21.7236Z"
                  fill="black"
                ></path>
                <path
                  opacity="0.1"
                  d="M35.7755 12.1405C35.7491 12.1536 35.7227 12.1536 35.6964 12.1536C37.99 15.2382 39.5191 18.995 39.5191 22.4091C39.5191 22.8573 39.4927 23.2923 39.44 23.7009C38.6359 30.305 31.6496 33.0073 25.4146 33.0073C22.2773 33.0073 18.9686 32.3086 16.3323 30.7927C18.6127 35.1164 24.2414 36.9618 29.3691 36.9618C35.6173 36.9618 42.5905 34.2595 43.3946 27.6555C43.4473 27.2336 43.4736 26.8118 43.4736 26.3636C43.4868 21.2623 40.0596 15.3964 35.7755 12.1405V12.1405Z"
                  fill="black"
                ></path>
                <path
                  d="M29.3823 23.8986C28.6177 23.8986 27.985 23.2791 27.985 22.5014C27.985 21.7236 28.6045 21.1041 29.3823 21.1041C30.1468 21.1041 30.7795 21.7236 30.7795 22.5014C30.7795 22.8705 31.0695 23.1605 31.4386 23.1605C31.8077 23.1605 32.0977 22.8705 32.0977 22.5014C32.0977 21.2359 31.2145 20.1814 30.0414 19.8782V19.0345C30.0414 18.6655 29.7514 18.3755 29.3823 18.3755C29.0132 18.3755 28.7232 18.6655 28.7232 19.0345V19.8782C27.55 20.1682 26.6668 21.2359 26.6668 22.5014C26.6668 23.9909 27.8795 25.2168 29.3823 25.2168C30.1468 25.2168 30.7795 25.8364 30.7795 26.6141C30.7795 27.3918 30.16 28.0114 29.3823 28.0114C28.6177 28.0114 27.985 27.3918 27.985 26.6141C27.985 26.245 27.695 25.955 27.3259 25.955C26.9568 25.955 26.6668 26.245 26.6668 26.6141C26.6668 27.8795 27.5368 28.9341 28.7232 29.2373V30.0809C28.7232 30.45 29.0132 30.74 29.3823 30.74C29.7514 30.74 30.0414 30.45 30.0414 30.0809V29.2373C31.2145 28.9473 32.0977 27.8795 32.0977 26.6141C32.0845 25.1245 30.8718 23.8986 29.3823 23.8986Z"
                  fill="#D9903B"
                ></path>
                <path
                  d="M79.7763 66.0146C80.781 66.0146 81.5954 65.2002 81.5954 64.1955C81.5954 63.1908 80.781 62.3764 79.7763 62.3764C78.7717 62.3764 77.9572 63.1908 77.9572 64.1955C77.9572 65.2002 78.7717 66.0146 79.7763 66.0146Z"
                  fill="#D9903B"
                ></path>
                <path
                  opacity="0.25"
                  d="M79.2886 63.0618C78.9459 63.0618 78.6691 63.3387 78.6691 63.6814C78.6691 64.0241 78.9459 64.3009 79.2886 64.3009C79.6313 64.3009 79.9082 64.0241 79.9082 63.6814C79.9082 63.3387 79.6313 63.0618 79.2886 63.0618Z"
                  fill="white"
                ></path>
                <path
                  d="M82.3468 79.895H29.1582C26.4163 79.895 24.2413 77.3905 24.8213 74.5432C25.2432 72.4737 27.1545 71.0368 29.2768 71.0368H82.3468C83.4672 71.0368 84.3636 70.1273 84.3636 69.02C84.3636 67.8996 83.454 67.0032 82.3468 67.0032H29.5141C24.8741 67.0032 20.8404 70.5755 20.6954 75.2155C20.5504 80.0137 24.3995 83.9419 29.1582 83.9419H82.3468C83.4672 83.9419 84.3636 83.0323 84.3636 81.925C84.3636 80.8046 83.454 79.895 82.3468 79.895Z"
                  fill="#93CFFF"
                ></path>
                <g opacity="0.1">
                  <path
                    d="M81.7273 69.02C81.7273 70.1405 80.8177 71.0368 79.7104 71.0368H82.3468C83.4673 71.0368 84.3636 70.1273 84.3636 69.02C84.3636 67.8996 83.4541 67.0032 82.3468 67.0032H79.7104C80.8177 67.0032 81.7273 67.8996 81.7273 69.02Z"
                    fill="black"
                  ></path>
                  <path
                    d="M82.3468 79.895H79.7104C80.8309 79.895 81.7273 80.8046 81.7273 81.9118C81.7273 83.0323 80.8177 83.9287 79.7104 83.9287H82.3468C83.4673 83.9287 84.3636 83.0191 84.3636 81.9118C84.3636 80.8046 83.4541 79.895 82.3468 79.895Z"
                    fill="black"
                  ></path>
                </g>
                <path
                  opacity="0.1"
                  d="M73.8577 55.8646L72.7636 55.68C67.6886 54.7968 62.495 54.3486 57.3541 54.3486C52.2 54.3486 47.0195 54.7968 41.9445 55.68L40.8504 55.8646C40.2177 55.97 39.7563 56.5236 39.7563 57.1696V61.8359L42.3927 62.9432V58.2636C52.2923 56.5368 62.4159 56.5368 72.3155 58.2636V62.93L74.9518 61.8227V57.1564C74.9518 56.5105 74.4905 55.97 73.8577 55.8646V55.8646Z"
                  fill="black"
                ></path>
                <path
                  d="M70.0087 31.9791C69.2441 31.9791 68.6114 31.3596 68.6114 30.5818C68.6114 29.8041 69.2309 29.1846 70.0087 29.1846C70.7732 29.1846 71.4059 29.8041 71.4059 30.5818C71.4059 30.9509 71.6959 31.2409 72.065 31.2409C72.4341 31.2409 72.7241 30.9509 72.7241 30.5818C72.7241 29.3164 71.8409 28.2618 70.6678 27.9586V27.115C70.6678 26.7459 70.3778 26.4559 70.0087 26.4559C69.6396 26.4559 69.3496 26.7459 69.3496 27.115V27.9586C68.1764 28.2486 67.2932 29.3164 67.2932 30.5818C67.2932 32.0714 68.5059 33.2973 70.0087 33.2973C70.7732 33.2973 71.4059 33.9168 71.4059 34.6946C71.4059 35.4723 70.7864 36.0918 70.0087 36.0918C69.2441 36.0918 68.6114 35.4723 68.6114 34.6946C68.6114 34.3255 68.3214 34.0355 67.9523 34.0355C67.5832 34.0355 67.2932 34.3255 67.2932 34.6946C67.2932 35.96 68.1632 37.0146 69.3496 37.3177V38.1614C69.3496 38.5305 69.6396 38.8205 70.0087 38.8205C70.3778 38.8205 70.6678 38.5305 70.6678 38.1614V37.3046C71.8409 37.0146 72.7241 35.9468 72.7241 34.6814C72.7109 33.1918 71.4982 31.9791 70.0087 31.9791Z"
                  fill="#D9903B"
                ></path>
              </svg>
              <p>
                Send Fees Directly <br></br> to Schools
              </p>
            </div>
            <div className="ml-[10rem] mb-[2rem] xxs:ml-0 md:mb-0 md:mx-auto">
              <svg
                className="border bg-white shadow-lg h-[10rem] w-[10rem] p-[2rem] rounded-[50%] cursor-pointer mx-auto border-[#f0e6e6] hover:h-[11.5rem] hover:w-[11.5rem] hover:p-[2.3rem] xxs:h-[6rem] xxs:w-[6rem] xxs:p-[1rem] xxs:hover:w-[6.5rem] xxs:hover:h-[6.5rem] xxs:hover:p-[1.2rem] xsm:h-[8rem] xsm:w-[8rem] xsm:p-[1.2rem] xsm:hover:w-[8.5rem] xsm:hover:h-[8.5rem] xsm:hover:p-[1.4rem] md:h-[5.5rem] md:w-[5.5rem] md:p-[1rem] md:hover:w-[6.5rem] md:hover:h-[6.5rem] md:hover:p-[1.2rem] lg:h-[7rem] lg:w-[7rem] lg:p-[1.2rem] lg:hover:w-[8rem] lg:hover:h-[8rem] lg:hover:p-[1.4rem] xl:h-[8rem] xl:w-[8rem] xl:p-[1.2rem] xl:hover:w-[9rem] xl:hover:h-[9rem] xl:hover:p-[1.6rem]          2xl:h-[9rem] 2xl:w-[9rem] 2xl:p-[1.3rem] 2xl:hover:w-[10rem] 2xl:hover:h-[10rem] 2xl:hover:p-[1.5rem]    "
                xmlns="http://www.w3.org/2000/svg"
                width="87"
                height="87"
                viewBox="0 0 87 87"
                fill="none"
              >
                <g clip-path="url(#clip0_1_195)">
                  <path
                    d="M29.8638 41.0899L13.9588 25.1849L16.6079 22.5359C17.6778 21.4659 16.9205 19.6373 15.4071 19.6373H2.1685C0.971077 19.6373 0 20.6083 0 21.8057V35.0444C0 36.5571 1.82865 37.3151 2.89863 36.2451L5.54835 33.5961L18.6363 46.6847C19.3246 47.3724 19.7109 48.3056 19.7109 49.278V86.8434H31.6055V45.2955C31.6055 43.7184 30.9789 42.2057 29.8638 41.0899V41.0899Z"
                    fill="#F9DB69"
                  ></path>
                  <path
                    d="M57.1362 58.0821L73.0412 42.1771L70.3921 39.5281C69.3222 38.4581 70.0795 36.6295 71.5929 36.6295H84.8315C86.0289 36.6295 87 37.5999 87 38.798V52.0366C87 53.5493 85.1713 54.3073 84.1014 53.2373L81.4517 50.5883L68.3637 65.4272C67.6754 66.1149 67.2891 67.0481 67.2891 68.0212V86.8434H55.3945V62.2877C55.3945 60.7106 56.0211 59.1979 57.1362 58.0821V58.0821Z"
                    fill="#5BB68D"
                  ></path>
                  <path
                    d="M54.3949 9.99619L45.0333 0.635216C44.1863 -0.211739 42.8137 -0.211739 41.9667 0.635216L32.6051 9.99619C31.5358 11.0662 32.2931 12.8955 33.8058 12.8955H37.5527V86.844H49.4472V12.8948H53.1942C54.7069 12.8955 55.4642 11.0662 54.3949 9.99619V9.99619Z"
                    fill="#87CDE9"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_1_195">
                    <rect width="87" height="87" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
              <p>
                Add Multiple <br></br> Beneficiaries
              </p>
            </div>
            <div className="ml-[10rem] mb-[2rem] xxs:ml-0 md:mb-0 md:mx-auto">
              <svg
                className="border bg-white shadow-lg h-[10rem] w-[10rem] p-[2rem] rounded-[50%] cursor-pointer mx-auto border-[#f0e6e6] hover:h-[11.5rem] hover:w-[11.5rem] hover:p-[2.3rem] xxs:h-[6rem] xxs:w-[6rem] xxs:p-[1rem] xxs:hover:w-[6.5rem] xxs:hover:h-[6.5rem] xxs:hover:p-[1.2rem] xsm:h-[8rem] xsm:w-[8rem] xsm:p-[1.2rem] xsm:hover:w-[8.5rem] xsm:hover:h-[8.5rem] xsm:hover:p-[1.4rem] md:h-[5.5rem] md:w-[5.5rem] md:p-[1rem] md:hover:w-[6.5rem] md:hover:h-[6.5rem] md:hover:p-[1.2rem] lg:h-[7rem] lg:w-[7rem] lg:p-[1.2rem] lg:hover:w-[8rem] lg:hover:h-[8rem] lg:hover:p-[1.4rem] xl:h-[8rem] xl:w-[8rem] xl:p-[1.2rem] xl:hover:w-[9rem] xl:hover:h-[9rem] xl:hover:p-[1.6rem]          2xl:h-[9rem] 2xl:w-[9rem] 2xl:p-[1.3rem] 2xl:hover:w-[10rem] 2xl:hover:h-[10rem] 2xl:hover:p-[1.5rem]    "
                xmlns="http://www.w3.org/2000/svg"
                width="97"
                height="85"
                viewBox="0 0 97 85"
                fill="none"
              >
                <g clip-path="url(#clip0_1_140)">
                  <path
                    d="M48.5 64.6039C66.1769 64.6039 80.5069 50.1392 80.5069 32.2962C80.5069 14.4531 66.1769 -0.0115509 48.5 -0.0115509C30.823 -0.0115509 16.493 14.4531 16.493 32.2962C16.493 50.1392 30.823 64.6039 48.5 64.6039Z"
                    fill="#478CF7"
                  ></path>
                  <path
                    d="M58.5267 1.60538C66.8292 7.44228 72.2626 17.148 72.2626 28.1348C72.2626 45.978 57.9327 60.4425 40.2556 60.4425C36.7534 60.4425 33.3827 59.8746 30.2285 58.8252C35.4092 62.4676 41.7075 64.6034 48.5 64.6034C66.1771 64.6034 80.507 50.1389 80.507 32.2957C80.507 17.9881 71.2927 5.8524 58.5267 1.60538Z"
                    fill="#377DE2"
                  ></path>
                  <path
                    d="M48.5 -0.0115509V32.2962H80.5069C80.5069 14.453 66.177 -0.0115509 48.5 -0.0115509V-0.0115509Z"
                    fill="#58595B"
                  ></path>
                  <path
                    d="M58.5695 1.61952C59.8833 2.76959 61.3236 3.74865 62.5817 4.98527C63.8146 6.19692 64.9515 7.50844 65.9767 8.90359C70.0481 14.4426 72.2626 21.239 72.2626 28.1348C72.2626 29.5445 72.1731 30.9335 71.9996 32.2957H80.0102H80.1821H80.5073C80.5069 18.0039 71.3132 5.87945 58.5695 1.61952V1.61952Z"
                    fill="#414042"
                  ></path>
                  <path
                    d="M72.6921 53.4502C77.5608 47.7822 80.5069 40.3866 80.5069 32.2957H48.5L72.6921 53.4502Z"
                    fill="#FFA91A"
                  ></path>
                  <path
                    d="M71.9996 32.2962C71.2795 37.9504 69.1108 43.1474 65.8819 47.4951C65.8819 47.4951 65.8823 47.4955 65.8827 47.4959L65.884 47.4972C65.9107 47.5205 66.1803 47.7564 66.5983 48.1221L66.6276 48.1475C68.3952 49.6933 72.6921 53.4502 72.6921 53.4502C77.5604 47.7822 80.5065 40.3866 80.5065 32.2962H71.9996Z"
                    fill="#F79219"
                  ></path>
                  <path
                    d="M66.846 24.7579C73.4607 24.7579 78.823 19.3452 78.823 12.6684C78.823 5.99154 73.4607 0.578888 66.846 0.578888C60.2313 0.578888 54.8691 5.99154 54.8691 12.6684C54.8691 19.3452 60.2313 24.7579 66.846 24.7579Z"
                    fill="#FFA91A"
                  ></path>
                  <path
                    d="M74.2223 3.14284C75.8138 5.19541 76.7623 7.77974 76.7623 10.5883C76.7623 17.2653 71.3998 22.6778 64.7854 22.6778C62.0033 22.6778 59.4426 21.72 57.4096 20.1139C59.6018 22.9412 63.0141 24.7583 66.8465 24.7583C73.4613 24.7583 78.8234 19.3454 78.8234 12.6688C78.8234 8.79958 77.0229 5.3556 74.2223 3.14284V3.14284Z"
                    fill="#F79219"
                  ></path>
                  <path
                    d="M67.936 19.8393H65.7562C63.799 19.8393 62.2066 18.2319 62.2066 16.2563C62.2066 15.797 62.5759 15.4241 63.031 15.4241C63.4861 15.4241 63.8554 15.797 63.8554 16.2563C63.8554 17.3144 64.7079 18.1749 65.7562 18.1749H67.936C68.9842 18.1749 69.8367 17.3144 69.8367 16.2563V15.1558C69.8367 14.0981 68.9842 13.2376 67.936 13.2376H65.7562C63.799 13.2376 62.2066 11.6302 62.2066 9.65463C62.2066 7.67904 63.799 6.0721 65.7562 6.0721H67.936C69.8932 6.0721 71.4856 7.67904 71.4856 9.65463C71.4856 10.114 71.1162 10.4868 70.6611 10.4868C70.206 10.4868 69.8367 10.114 69.8367 9.65463C69.8367 8.59693 68.9842 7.73646 67.936 7.73646H65.7562C64.7079 7.73646 63.8554 8.59693 63.8554 9.65463C63.8554 10.7128 64.7079 11.5732 65.7562 11.5732H67.936C69.8932 11.5732 71.4856 13.1802 71.4856 15.1558V16.2563C71.486 18.2319 69.8932 19.8393 67.936 19.8393Z"
                    fill="white"
                  ></path>
                  <path
                    d="M66.8465 20.9398C66.3914 20.9398 66.022 20.567 66.022 20.1076V5.80372C66.022 5.34436 66.3914 4.97154 66.8465 4.97154C67.3016 4.97154 67.6709 5.34436 67.6709 5.80372V20.1076C67.6709 20.567 67.3016 20.9398 66.8465 20.9398Z"
                    fill="white"
                  ></path>
                  <path
                    d="M93.2749 58.4491C89.095 61.7944 74.7004 73.0954 64.8361 78.1468C53.1827 84.1176 41.9498 78.1468 32.8233 77.0191C27.3961 76.1965 21.914 79.5785 18.9646 82.8777L18.8987 82.8902L17.6579 83.0649V60.2383C23.1276 52.0097 31.4 53.1065 40.3092 52.2373C46.5811 51.4267 52.6843 49.5427 58.2571 46.5115C59.3647 45.6855 60.5564 46.33 60.7881 47.6224C61.6064 52.1902 58.8837 57.1937 54.6172 58.1162L52.3706 58.6321C51.5874 58.8215 51.6691 59.9358 52.449 60.0718C60.2811 62.8014 68.2451 60.9997 75.8835 57.6418C80.9331 55.4241 89.0126 49.7694 93.5676 55.9026C94.1447 56.6807 94.0086 57.8624 93.2749 58.4491V58.4491Z"
                    fill="#FCC4AE"
                  ></path>
                  <path
                    d="M24.3894 58.1266C23.5484 58.1399 23.2471 56.9337 24.0142 56.5538C27.6459 54.686 31.97 54.4446 36.1499 54.207C38.9723 54.0797 41.693 53.8317 44.4721 53.2375C45.5357 53.052 45.8592 54.6202 44.7978 54.8686C41.956 55.4815 39.1739 55.7229 36.3189 55.8635C31.7895 56.0474 27.8524 56.5126 24.3894 58.1266V58.1266ZM47.8486 54.1321C46.8906 54.128 46.7138 52.7362 47.6425 52.4927C48.8416 52.2485 49.0683 54.0722 47.8486 54.1321Z"
                    fill="#FFD2C0"
                  ></path>
                  <path
                    d="M93.5676 55.9026C92.4698 54.4246 91.1672 53.6312 89.7521 53.3091C89.663 53.6911 89.4606 54.0418 89.1527 54.2882C84.9728 57.6335 70.5782 68.9345 60.7139 73.9859C49.0605 79.9567 37.8276 73.9858 28.7012 72.8582C24.7056 72.2528 20.6803 73.9259 17.6579 76.1674V83.0649L18.8987 82.8902L18.9646 82.8777C21.914 79.5785 27.3961 76.1965 32.8233 77.0191C41.9498 78.1467 53.1827 84.1176 64.8361 78.1467C74.7004 73.0954 89.095 61.7944 93.2749 58.4491C94.0086 57.8624 94.1447 56.6807 93.5676 55.9026Z"
                    fill="#F2AD97"
                  ></path>
                  <path
                    d="M93.5676 55.9026C92.99 55.1249 92.3556 54.537 91.6784 54.1076C92.044 54.8661 91.8626 55.8502 91.2138 56.369C87.0339 59.7144 72.6393 71.0154 62.775 76.0667C51.1216 82.0376 39.8887 76.0667 30.7623 74.9391C25.7687 74.1823 20.7289 76.985 17.6579 80.0075V83.0653L18.8987 82.8906L18.9646 82.8781C21.914 79.5789 27.3961 76.197 32.8233 77.0196C41.9498 78.1472 53.1827 84.1181 64.8361 78.1472C74.7004 73.0958 89.095 61.7948 93.2749 58.4495C94.0086 57.8624 94.1447 56.6807 93.5676 55.9026Z"
                    fill="#E59076"
                  ></path>
                  <path
                    d="M23.6816 82.2132L18.9654 82.8794L18.8991 82.889L17.6587 83.065L16.0676 83.2888C15.0416 83.4344 14.0955 82.7109 13.9512 81.6752L10.3955 56.0079C10.2512 54.9722 10.9664 54.0173 11.9924 53.8717L19.6064 52.7957C20.092 52.7287 20.5624 52.8531 20.9358 53.1194C21.3493 53.4065 21.6453 53.8642 21.7228 54.4097L21.9276 55.8951L24.5675 74.9433L25.0378 78.3428L25.2785 80.0791C25.4228 81.1127 24.706 82.0676 23.6816 82.2132V82.2132Z"
                    fill="#F1F2F2"
                  ></path>
                  <path
                    d="M25.2786 80.0787L25.0378 78.3423L24.5675 74.9429L21.9277 55.8947L21.7228 54.4092C21.6449 53.8638 21.3493 53.4061 20.9359 53.119C20.5933 52.8747 20.1679 52.7578 19.7256 52.7902L19.8666 53.8138L22.5064 72.862L22.9767 76.2615L23.2175 77.9978C23.3618 79.0314 22.6449 79.9867 21.6206 80.1323L16.9044 80.7985L16.838 80.8081L15.5976 80.9841L14.0065 81.2079C13.9661 81.2138 13.9273 81.2096 13.8874 81.2125L13.9513 81.6748C14.0955 82.7104 15.0416 83.434 16.0676 83.2884L17.6587 83.0645L18.8991 82.8885L18.9655 82.879L23.6816 82.2128C24.706 82.0676 25.4228 81.1127 25.2786 80.0787V80.0787Z"
                    fill="#E6E7E8"
                  ></path>
                  <path
                    d="M5.6187 84.9806H14.0621C15.4678 84.9806 16.6072 83.8305 16.6072 82.4117V52.4029C16.6072 50.984 15.4678 49.8339 14.0621 49.8339H5.6187C4.21304 49.8339 3.07367 50.984 3.07367 52.4029V82.4117C3.07326 83.8301 4.21304 84.9806 5.6187 84.9806Z"
                    fill="#8C3F0A"
                  ></path>
                  <path
                    d="M14.5053 49.8747C14.5304 50.0203 14.5465 50.1689 14.5465 50.322V80.3308C14.5465 81.7497 13.4071 82.8997 12.0015 82.8997H3.55762C3.40634 82.8997 3.25876 82.8839 3.11449 82.8581C3.32431 84.0635 4.36433 84.9802 5.61871 84.9802H14.0621C15.4678 84.9802 16.6072 83.8301 16.6072 82.4112V52.4024C16.6072 51.1367 15.6991 50.0869 14.5053 49.8747V49.8747Z"
                    fill="#7C3108"
                  ></path>
                  <path
                    d="M12.4666 75.854C12.8118 74.38 11.9079 72.9027 10.4477 72.5543C8.98744 72.2059 7.52388 73.1183 7.17871 74.5923C6.83354 76.0662 7.73747 77.5435 9.19769 77.8919C10.6579 78.2404 12.1215 77.3279 12.4666 75.854Z"
                    fill="white"
                  ></path>
                  <path
                    d="M11.3032 72.8895C11.574 73.3159 11.7331 73.8219 11.7331 74.3662C11.7331 75.8811 10.5167 77.109 9.01578 77.109C8.4766 77.109 7.97535 76.9484 7.55283 76.675C8.03594 77.4357 8.87852 77.9412 9.84022 77.9412C11.3411 77.9412 12.5575 76.7133 12.5575 75.1983C12.5575 74.228 12.0567 73.3771 11.3032 72.8895Z"
                    fill="#E6E7E8"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_1_140">
                    <rect width="97" height="85" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
              <p>
                View Outstanding <br></br> Fees
              </p>
            </div>
            <div className="ml-[10rem] mb-[2rem] xxs:ml-0 xxs:mb-0 md:mb-0 md:mx-auto">
              <svg
                className="border bg-white shadow-lg h-[10rem] w-[10rem] p-[2rem] rounded-[50%] cursor-pointer mx-auto border-[#f0e6e6] hover:h-[11.5rem] hover:w-[11.5rem] hover:p-[2.3rem] xxs:h-[6rem] xxs:w-[6rem] xxs:p-[1rem] xxs:hover:w-[6.5rem] xxs:hover:h-[6.5rem] xxs:hover:p-[1.2rem] xsm:h-[8rem] xsm:w-[8rem] xsm:p-[1.2rem] xsm:hover:w-[8.5rem] xsm:hover:h-[8.5rem] xsm:hover:p-[1.4rem]  md:h-[5.5rem] md:w-[5.5rem] md:p-[1rem] md:hover:w-[6.5rem] md:hover:h-[6.5rem] md:hover:p-[1.2rem] lg:h-[7rem] lg:w-[7rem] lg:p-[1.2rem] lg:hover:w-[8rem] lg:hover:h-[8rem] lg:hover:p-[1.4rem] xl:h-[8rem] xl:w-[8rem] xl:p-[1.2rem] xl:hover:w-[9rem] xl:hover:h-[9rem] xl:hover:p-[1.6rem]          2xl:h-[9rem] 2xl:w-[9rem] 2xl:p-[1.3rem] 2xl:hover:w-[10rem] 2xl:hover:h-[10rem] 2xl:hover:p-[1.5rem]    "
                xmlns="http://www.w3.org/2000/svg"
                width="87"
                height="87"
                viewBox="0 0 87 87"
                fill="none"
              >
                <g clip-path="url(#clip0_1_100)">
                  <path
                    d="M9 0H79.5C81.1567 0 82.5 1.34326 82.5 3V45C82.5 46.6568 81.1567 48 79.5 48H9C7.34326 48 6 46.6568 6 45V3C6 1.34326 7.34326 0 9 0Z"
                    fill="#E6AC69"
                  ></path>
                  <path
                    d="M3 13.5H19.5C21.1567 13.5 22.5 14.8433 22.5 16.5V25.5C22.5 27.1567 21.1567 28.5 19.5 28.5H0V16.5C0 14.8433 1.34326 13.5 3 13.5Z"
                    fill="#FFBF75"
                  ></path>
                  <path
                    d="M0 28.5H6V34.5H3C1.34326 34.5 0 33.1567 0 31.5V28.5Z"
                    fill="#CC995E"
                  ></path>
                  <path
                    d="M18 21C18 22.6568 16.6567 24 15 24C13.3433 24 12 22.6568 12 21C12 19.3433 13.3433 18 15 18C16.6567 18 18 19.3433 18 21Z"
                    fill="#B38652"
                  ></path>
                  <path d="M54 9H67.5V12H54V9Z" fill="#55965C"></path>
                  <path
                    d="M36.5244 19.8618L47.0244 10.8618L48.9756 13.1382L38.4756 22.1382L36.5244 19.8618Z"
                    fill="#55965C"
                  ></path>
                  <path d="M27 21H31.5V24H27V21Z" fill="#55965C"></path>
                  <path d="M73.5 9H78V12H73.5V9Z" fill="#55965C"></path>
                  <path
                    d="M49.5 7.5H52.5C53.3284 7.5 54 8.17163 54 9V12C54 12.8284 53.3284 13.5 52.5 13.5H49.5C48.6716 13.5 48 12.8284 48 12V9C48 8.17163 48.6716 7.5 49.5 7.5Z"
                    fill="#55965C"
                  ></path>
                  <path
                    d="M52.5 15H49.5C47.844 14.9978 46.5022 13.656 46.5 12V9C46.5022 7.344 47.844 6.0022 49.5 6H52.5C54.156 6.0022 55.4978 7.344 55.5 9V12C55.4978 13.656 54.156 14.9978 52.5 15ZM49.5 9V12H52.5029L52.5 9H49.5Z"
                    fill="#73C97C"
                  ></path>
                  <path
                    d="M68.9999 7.5H71.9999C72.8283 7.5 73.4999 8.17163 73.4999 9V12C73.4999 12.8284 72.8283 13.5 71.9999 13.5H68.9999C68.1716 13.5 67.4999 12.8284 67.4999 12V9C67.4999 8.17163 68.1716 7.5 68.9999 7.5Z"
                    fill="#55965C"
                  ></path>
                  <path
                    d="M72 15H69C67.344 14.9978 66.0022 13.656 66 12V9C66.0022 7.344 67.344 6.0022 69 6H72C73.656 6.0022 74.9978 7.344 75 9V12C74.9978 13.656 73.656 14.9978 72 15ZM69 9V12H72.0029L72 9H69Z"
                    fill="#73C97C"
                  ></path>
                  <path d="M45 24H48V27H45V24Z" fill="#B38652"></path>
                  <path
                    d="M33 19.5H36C36.8284 19.5 37.5 20.1716 37.5 21V24C37.5 24.8284 36.8284 25.5 36 25.5H33C32.1716 25.5 31.5 24.8284 31.5 24V21C31.5 20.1716 32.1716 19.5 33 19.5Z"
                    fill="#55965C"
                  ></path>
                  <path
                    d="M36 27H33C31.344 26.9978 30.0022 25.656 30 24V21C30.0022 19.344 31.344 18.0022 33 18H36C37.656 18.0022 38.9978 19.344 39 21V24C38.9978 25.656 37.656 26.9978 36 27ZM33 21V24H36.0029L36 21H33Z"
                    fill="#73C97C"
                  ></path>
                  <path d="M50.9999 24H72V27H50.9999V24Z" fill="#B38652"></path>
                  <path d="M75 24H78V27H75V24Z" fill="#B38652"></path>
                  <path d="M48 18H51V21H48V18Z" fill="#B38652"></path>
                  <path d="M54 18H72V21H54V18Z" fill="#B38652"></path>
                  <path d="M75 18H78V21H75V18Z" fill="#B38652"></path>
                  <path
                    d="M72 73.4999H16.5C14.8433 73.4999 13.5 72.1567 13.5 70.4999V37.5C13.5 35.8432 14.8433 34.5 16.5 34.5H72C73.6568 34.5 75 35.8432 75 37.5V70.4999C75 72.1567 73.6568 73.4999 72 73.4999Z"
                    fill="#D6D6D6"
                  ></path>
                  <path d="M75 55.5H13.5V42H75V55.5Z" fill="#707070"></path>
                  <path
                    d="M84 87H28.5C26.8433 87 25.5 85.6567 25.5 84V51C25.5 49.3433 26.8433 48 28.5 48H84C85.6568 48 87 49.3433 87 51V84C87 85.6567 85.6568 87 84 87Z"
                    fill="#EFEFEF"
                  ></path>
                  <path
                    d="M78 69H69C67.3433 69 66 67.6567 66 66V57C66 55.3433 67.3433 54 69 54H78C79.6567 54 81 55.3433 81 57V66C81 67.6567 79.6567 69 78 69Z"
                    fill="#FFD04A"
                  ></path>
                  <path
                    d="M51 79.5001H46.5C45.6716 79.5001 45 78.8284 45 78.0001V73.5001C45 72.6717 45.6716 72.0001 46.5 72.0001H51C51.8284 72.0001 52.5 72.6717 52.5 73.5001V78.0001C52.5 78.8284 51.8284 79.5001 51 79.5001Z"
                    fill="#75C1FF"
                  ></path>
                  <path
                    d="M37.5 79.5001H33C32.1716 79.5001 31.5 78.8284 31.5 78.0001V73.5001C31.5 72.6717 32.1716 72.0001 33 72.0001H37.5C38.3284 72.0001 39 72.6717 39 73.5001V78.0001C39 78.8284 38.3284 79.5001 37.5 79.5001Z"
                    fill="#73C97C"
                  ></path>
                  <path
                    d="M70.5 69H73.5V64.5H81V61.5H73.5C71.844 61.5015 70.5015 62.844 70.5 64.5V69Z"
                    fill="#E6BB43"
                  ></path>
                  <path d="M70.5 54H73.5V58.5H70.5V54Z" fill="#E6BB43"></path>
                  <path d="M58.5 57H61.5V60H58.5V57Z" fill="#BCBCBC"></path>
                  <path d="M36 57H55.5V60H36V57Z" fill="#BCBCBC"></path>
                  <path d="M30 57H33V60H30V57Z" fill="#BCBCBC"></path>
                  <path d="M58.5 63H61.5V66H58.5V63Z" fill="#BCBCBC"></path>
                  <path d="M36 63H55.5V66H36V63Z" fill="#BCBCBC"></path>
                  <path d="M30 63H33V66H30V63Z" fill="#BCBCBC"></path>
                  <path d="M78 75H81V78H78V75Z" fill="#BCBCBC"></path>
                  <path d="M72 75H75V78H72V75Z" fill="#BCBCBC"></path>
                  <path d="M66 75H69V78H66V75Z" fill="#BCBCBC"></path>
                  <path d="M60 75H63V78H60V75Z" fill="#BCBCBC"></path>
                </g>
                <defs>
                  <clipPath id="clip0_1_100">
                    <rect width="87" height="87" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
              <p>
                View Payment <br></br> History
              </p>
            </div>
          </div>
          <div className="donor flex justify-center text-center xxs:block md:flex">
            <div className="mb-[2rem] md:mx-5">
              <svg
                className="border bg-white shadow-lg h-[10rem] w-[10rem] p-[2rem] rounded-[50%] cursor-pointer mx-auto border-[#f0e6e6] hover:h-[11.5rem] hover:w-[11.5rem] hover:p-[2.3rem] xxs:h-[6rem] xxs:w-[6rem] xxs:p-[1rem] xxs:hover:w-[6.5rem] xxs:hover:h-[6.5rem] xxs:hover:p-[1.2rem] xsm:h-[8rem] xsm:w-[8rem] xsm:p-[1.2rem] xsm:hover:w-[8.5rem] xsm:hover:h-[8.5rem] xsm:hover:p-[1.4rem] md:h-[5.5rem] md:w-[5.5rem] md:p-[1rem] md:hover:w-[6.5rem] md:hover:h-[6.5rem] md:hover:p-[1.2rem] lg:h-[7rem] lg:w-[7rem] lg:p-[1.2rem] lg:hover:w-[8rem] lg:hover:h-[8rem] lg:hover:p-[1.4rem] xl:h-[8rem] xl:w-[8rem] xl:p-[1.2rem] xl:hover:w-[9rem] xl:hover:h-[9rem] xl:hover:p-[1.6rem]          2xl:h-[9rem] 2xl:w-[9rem] 2xl:p-[1.3rem] 2xl:hover:w-[10rem] 2xl:hover:h-[10rem] 2xl:hover:p-[1.5rem]    "
                xmlns="http://www.w3.org/2000/svg"
                width="87"
                height="87"
                viewBox="0 0 87 87"
                fill="none"
              >
                <path
                  d="M59.8125 12.2344V61.1719C59.8189 62.7738 59.3454 64.341 58.4531 65.6714C57.7117 66.7962 56.7024 67.7191 55.5161 68.3574C54.3297 68.9956 53.0034 69.3292 51.6562 69.3281H24.4687C23.3973 69.3292 22.3362 69.119 21.3462 68.7095C20.3561 68.2999 19.4566 67.6992 18.699 66.9416C17.9414 66.184 17.3407 65.2845 16.9311 64.2944C16.5216 63.3044 16.3114 62.2433 16.3125 61.1719V12.2344C16.3125 10.0712 17.1718 7.99663 18.7014 6.46704C20.231 4.93744 22.3056 4.07813 24.4687 4.07812H51.6562C53.8194 4.07813 55.894 4.93744 57.4236 6.46704C58.9532 7.99663 59.8125 10.0712 59.8125 12.2344Z"
                  fill="#5AAAE7"
                ></path>
                <path
                  d="M33.8484 58.0589C33.8484 58.7744 33.661 59.4774 33.3047 60.0979L27.9759 69.3281H25.2843L24.4687 67.9687L30.5859 57.3792C30.9422 56.7587 31.1297 56.0556 31.1297 55.3401C31.1329 54.9391 31.0733 54.54 30.9529 54.1575C31.2539 54.2399 31.5421 54.3634 31.8093 54.5245C32.4314 54.8807 32.9478 55.3955 33.306 56.0164C33.6642 56.6373 33.8514 57.3421 33.8484 58.0589V58.0589Z"
                  fill="#3D9AE2"
                ></path>
                <path
                  d="M58.4531 51.6562V65.6714C57.7116 66.7962 56.7024 67.7191 55.516 68.3574C54.3297 68.9956 53.0034 69.3292 51.6562 69.3281H50.2968V51.6562C50.2968 50.5747 50.7265 49.5374 51.4913 48.7726C52.2561 48.0078 53.2934 47.5781 54.375 47.5781C55.4566 47.5781 56.4938 48.0078 57.2586 48.7726C58.0234 49.5374 58.4531 50.5747 58.4531 51.6562Z"
                  fill="#3D9AE2"
                ></path>
                <path
                  d="M33.7397 58.9833C33.6532 59.3751 33.5065 59.7512 33.3047 60.098L32.625 61.267V61.1719C32.6231 60.8149 32.6931 60.4612 32.8309 60.1319C32.9687 59.8026 33.1714 59.5044 33.427 59.2552C33.5248 59.1574 33.6293 59.0666 33.7397 58.9833V58.9833Z"
                  fill="#D8D7DA"
                ></path>
                <path
                  d="M31.1297 55.3401C31.1307 54.4416 30.8349 53.5678 30.2882 52.8547C29.7416 52.1415 28.9747 51.6288 28.1067 51.3963C27.2387 51.1639 26.3183 51.2245 25.4883 51.569C24.6584 51.9134 23.9654 52.5223 23.5172 53.3011L17.0329 64.5295C16.5564 63.4744 16.3108 62.3296 16.3125 61.1719V39.4491C15.8617 39.5115 15.4339 39.6861 15.0681 39.9569C14.7024 40.2276 14.4104 40.5859 14.219 40.9987L8.04748 54.3886C7.2216 56.1689 6.79476 58.1082 6.79685 60.0708V84.2812L28.5469 84.2677V77.0086C28.5439 75.5285 28.1399 74.0769 27.3778 72.8081L25.2844 69.3281L24.4687 67.9687L30.5859 57.3792C30.9422 56.7587 31.1297 56.0557 31.1297 55.3401V55.3401Z"
                  fill="#FFC477"
                ></path>
                <path
                  d="M40.7812 58.4531C41.5016 58.4553 42.1919 58.7424 42.7013 59.2518C43.2107 59.7612 43.4978 60.4515 43.5 61.1719C43.4947 61.8913 43.2065 62.5797 42.6978 63.0884C42.1891 63.5971 41.5007 63.8853 40.7812 63.8906H35.3438C34.6234 63.8885 33.9331 63.6013 33.4237 63.0919C32.9143 62.5825 32.6272 61.8923 32.625 61.1719C32.6303 60.4525 32.9185 59.764 33.4272 59.2553C33.9359 58.7466 34.6243 58.4585 35.3438 58.4531H40.7812Z"
                  fill="#E6E7E8"
                ></path>
                <path
                  d="M72.0469 67.9688C72.0469 66.8872 72.4766 65.8499 73.2414 65.0851C74.0062 64.3203 75.0435 63.8906 76.125 63.8906C77.2066 63.8906 78.2439 64.3203 79.0087 65.0851C79.7735 65.8499 80.2032 66.8872 80.2032 67.9688V84.2812H43.6496L37.7907 74.1403C37.4326 73.5202 37.2442 72.8167 37.2443 72.1006C37.2444 71.3845 37.4331 70.6811 37.7913 70.0611C38.1496 69.441 38.6647 68.9262 39.2851 68.5685C39.9054 68.2108 40.609 68.0227 41.325 68.0231C42.0418 68.0202 42.7466 68.2073 43.3675 68.5655C43.9884 68.9237 44.5032 69.4402 44.8594 70.0622L47.5782 74.7656V48.9375C47.5782 47.8559 48.0078 46.8186 48.7726 46.0538C49.5374 45.289 50.5747 44.8594 51.6563 44.8594C52.7379 44.8594 53.7752 45.289 54.54 46.0538C55.3048 46.8186 55.7344 47.8559 55.7344 48.9375V65.25C55.7344 64.1684 56.1641 63.1311 56.9289 62.3663C57.6937 61.6015 58.731 61.1719 59.8125 61.1719C60.8941 61.1719 61.9314 61.6015 62.6962 62.3663C63.461 63.1311 63.8907 64.1684 63.8907 65.25V66.6094C63.8907 65.5278 64.3203 64.4905 65.0851 63.7257C65.8499 62.9609 66.8872 62.5312 67.9688 62.5312C69.0504 62.5312 70.0877 62.9609 70.8525 63.7257C71.6173 64.4905 72.0469 65.5278 72.0469 66.6094V67.9688Z"
                  fill="#FFC477"
                ></path>
                <path
                  d="M40.7812 47.5781C47.5381 47.5781 53.0156 42.1006 53.0156 35.3438C53.0156 28.5869 47.5381 23.1094 40.7812 23.1094C34.0244 23.1094 28.5468 28.5869 28.5468 35.3438C28.5468 42.1006 34.0244 47.5781 40.7812 47.5781Z"
                  fill="#3D9AE2"
                ></path>
                <path
                  d="M38.0625 44.8594C44.8193 44.8594 50.2968 39.3819 50.2968 32.625C50.2968 25.8681 44.8193 20.3906 38.0625 20.3906C31.3056 20.3906 25.8281 25.8681 25.8281 32.625C25.8281 39.3819 31.3056 44.8594 38.0625 44.8594Z"
                  fill="#FFD422"
                ></path>
                <path
                  d="M27.1875 8.15625H29.9062V10.875H27.1875V8.15625Z"
                  fill="#3D9AE2"
                ></path>
                <path
                  d="M32.625 8.15625H43.5V10.875H32.625V8.15625Z"
                  fill="#3D9AE2"
                ></path>
                <path
                  d="M39.4219 31.2656H36.7031C36.3426 31.2656 35.9968 31.1224 35.7419 30.8675C35.487 30.6125 35.3438 30.2668 35.3438 29.9062C35.3438 29.5457 35.487 29.2 35.7419 28.945C35.9968 28.6901 36.3426 28.5469 36.7031 28.5469H39.4219C39.7824 28.5469 40.1282 28.6901 40.3831 28.945C40.638 29.2 40.7812 29.5457 40.7812 29.9062H43.5C43.5 28.8247 43.0703 27.7874 42.3055 27.0226C41.5407 26.2578 40.5035 25.8281 39.4219 25.8281V24.4688H36.7031V25.8281C35.6215 25.8281 34.5843 26.2578 33.8195 27.0226C33.0547 27.7874 32.625 28.8247 32.625 29.9062C32.625 30.9878 33.0547 32.0251 33.8195 32.7899C34.5843 33.5547 35.6215 33.9844 36.7031 33.9844H39.4219C39.7824 33.9844 40.1282 34.1276 40.3831 34.3825C40.638 34.6375 40.7812 34.9832 40.7812 35.3438C40.7812 35.7043 40.638 36.05 40.3831 36.305C40.1282 36.5599 39.7824 36.7031 39.4219 36.7031H36.7031C36.3426 36.7031 35.9968 36.5599 35.7419 36.305C35.487 36.05 35.3438 35.7043 35.3438 35.3438H32.625C32.625 36.4253 33.0547 37.4626 33.8195 38.2274C34.5843 38.9922 35.6215 39.4219 36.7031 39.4219V40.7812H39.4219V39.4219C40.5035 39.4219 41.5407 38.9922 42.3055 38.2274C43.0703 37.4626 43.5 36.4253 43.5 35.3438C43.5 34.2622 43.0703 33.2249 42.3055 32.4601C41.5407 31.6953 40.5035 31.2656 39.4219 31.2656Z"
                  fill="#FF5023"
                ></path>
                <path
                  d="M5.83579 18.0702L1.75766 22.1483L3.67982 24.0705L5.43749 22.3128V38.0625H8.15624V22.3128L9.91391 24.0705L11.8361 22.1483L7.75794 18.0702C7.50302 17.8153 7.15732 17.6722 6.79686 17.6722C6.43641 17.6722 6.09071 17.8153 5.83579 18.0702V18.0702Z"
                  fill="#FF9811"
                ></path>
                <path
                  d="M74.3673 22.1483L70.2892 18.0702C70.0343 17.8153 69.6886 17.6722 69.3281 17.6722C68.9677 17.6722 68.622 17.8153 68.367 18.0702L64.2889 22.1483L66.2111 24.0705L67.9687 22.3128V38.0625H70.6875V22.3128L72.4452 24.0705L74.3673 22.1483Z"
                  fill="#FF9811"
                ></path>
                <path
                  d="M67.9687 40.7812H70.6875V43.5H67.9687V40.7812Z"
                  fill="#FF9811"
                ></path>
                <path
                  d="M67.9687 46.2188H70.6875V48.9375H67.9687V46.2188Z"
                  fill="#FF9811"
                ></path>
                <path
                  d="M5.4375 40.7812H8.15625V43.5H5.4375V40.7812Z"
                  fill="#FF9811"
                ></path>
                <path
                  d="M29.9062 84.2676H27.1875V77.0154C27.1877 75.78 26.8512 74.568 26.2142 73.5095L23.3038 68.6702C23.1786 68.4623 23.1113 68.2248 23.1089 67.9821C23.1065 67.7395 23.1691 67.5007 23.2902 67.2904L29.3149 56.8558C29.5098 56.5304 29.6378 56.1695 29.6916 55.794C29.7454 55.4186 29.7238 55.0362 29.6281 54.6691C29.5324 54.3021 29.3646 53.9578 29.1343 53.6564C28.9041 53.355 28.6161 53.1025 28.2872 52.9136C27.6667 52.598 26.9487 52.5324 26.2813 52.7305C25.6138 52.9285 25.0478 53.375 24.6998 53.978L17.2233 66.9274C17.134 67.0828 17.0149 67.2189 16.8729 67.328C16.7308 67.4372 16.5686 67.5172 16.3955 67.5634C16.2225 67.6096 16.042 67.6212 15.8644 67.5975C15.6869 67.5737 15.5158 67.5151 15.3609 67.425C15.2062 67.3357 15.0705 67.2168 14.9617 67.0751C14.8529 66.9334 14.7732 66.7716 14.7269 66.599C14.6807 66.4264 14.669 66.2464 14.6924 66.0693C14.7158 65.8921 14.7739 65.7214 14.8634 65.5667L22.34 52.6187C22.7464 51.9173 23.3042 51.3155 23.9726 50.857C24.6411 50.3985 25.4034 50.0949 26.2041 49.9683C27.0048 49.8417 27.8236 49.8953 28.6009 50.1253C29.3782 50.3552 30.0944 50.7556 30.6974 51.2973C31.626 52.1568 32.2312 53.3093 32.4116 54.5617C32.592 55.8141 32.3367 57.0905 31.6884 58.1771L26.0415 67.9565L28.5469 72.108C29.4371 73.5895 29.9071 75.2856 29.9062 77.014V84.2676Z"
                  fill="#FFB655"
                ></path>
                <path
                  d="M57.0937 65.25C57.0937 64.4992 56.4851 63.8906 55.7343 63.8906C54.9836 63.8906 54.375 64.4992 54.375 65.25V70.6875C54.375 71.4383 54.9836 72.0469 55.7343 72.0469C56.4851 72.0469 57.0937 71.4383 57.0937 70.6875V65.25Z"
                  fill="#FFB655"
                ></path>
                <path
                  d="M65.25 65.25C65.25 64.4992 64.6414 63.8906 63.8906 63.8906C63.1398 63.8906 62.5312 64.4992 62.5312 65.25V70.6875C62.5312 71.4383 63.1398 72.0469 63.8906 72.0469C64.6414 72.0469 65.25 71.4383 65.25 70.6875V65.25Z"
                  fill="#FFB655"
                ></path>
                <path
                  d="M73.4062 66.6094C73.4062 65.8586 72.7976 65.25 72.0468 65.25C71.2961 65.25 70.6875 65.8586 70.6875 66.6094V70.6875C70.6875 71.4383 71.2961 72.0469 72.0468 72.0469C72.7976 72.0469 73.4062 71.4383 73.4062 70.6875V66.6094Z"
                  fill="#FFB655"
                ></path>
              </svg>
              <p>
                Goverment <br></br> Bursary Payments
              </p>
            </div>
            <div className="ml-[10rem] mb-[2rem] xxs:ml-0 md:mx-5">
              <svg
                className="border bg-white shadow-lg h-[10rem] w-[10rem] p-[2rem] rounded-[50%] cursor-pointer mx-auto border-[#f0e6e6] hover:h-[11.5rem] hover:w-[11.5rem] hover:p-[2.3rem] xxs:h-[6rem] xxs:w-[6rem] xxs:p-[1rem] xxs:hover:w-[6.5rem] xxs:hover:h-[6.5rem] xxs:hover:p-[1.2rem] xsm:h-[8rem] xsm:w-[8rem] xsm:p-[1.2rem] xsm:hover:w-[8.5rem] xsm:hover:h-[8.5rem] xsm:hover:p-[1.4rem] md:h-[5.5rem] md:w-[5.5rem] md:p-[1rem] md:hover:w-[6.5rem] md:hover:h-[6.5rem] md:hover:p-[1.2rem] lg:h-[7rem] lg:w-[7rem] lg:p-[1.2rem] lg:hover:w-[8rem] lg:hover:h-[8rem] lg:hover:p-[1.4rem] xl:h-[8rem] xl:w-[8rem] xl:p-[1.2rem] xl:hover:w-[9rem] xl:hover:h-[9rem] xl:hover:p-[1.6rem]          2xl:h-[9rem] 2xl:w-[9rem] 2xl:p-[1.3rem] 2xl:hover:w-[10rem] 2xl:hover:h-[10rem] 2xl:hover:p-[1.5rem]    "
                xmlns="http://www.w3.org/2000/svg"
                width="87"
                height="87"
                viewBox="0 0 87 87"
                fill="none"
              >
                <g clip-path="url(#clip0_1_66)">
                  <path
                    d="M86.3547 15.224L79.4997 19.874L76.4997 21.914L68.9997 26.999H17.9997L0.644695 15.224C0.168868 14.8923 -0.074746 14.3173 0.0180135 13.7447C0.110773 13.1722 0.523487 12.7035 1.0797 12.539L42.6447 0.118996C43.2027 -0.0460333 43.7966 -0.0460333 44.3547 0.118996L76.5747 9.749L79.4997 10.619L85.9347 12.539C86.487 12.7092 86.894 13.1793 86.9833 13.7503C87.0727 14.3213 86.8286 14.8933 86.3547 15.224V15.224Z"
                    fill="#295474"
                  ></path>
                  <path
                    d="M18 46.5V27C18 22.86 29.415 19.5 43.5 19.5C57.585 19.5 69 22.86 69 27V46.5H18Z"
                    fill="#346D94"
                  ></path>
                  <path
                    d="M69 46.5C69 48.135 67.23 49.635 64.215 50.865H22.785C19.77 49.635 18 48.135 18 46.5C18 42.36 29.415 39 43.5 39C57.585 39 69 42.36 69 46.5V46.5Z"
                    fill="#295474"
                  ></path>
                  <path
                    d="M81 34.5C81.0014 35.8581 80.0902 37.0478 78.7787 37.4003C77.4671 37.7528 76.0823 37.1802 75.4027 36.0044C74.723 34.8286 74.918 33.3429 75.8781 32.3823C76.8382 31.4217 78.3238 31.226 79.5 31.905C80.4259 32.4417 80.997 33.4298 81 34.5Z"
                    fill="#F7AD00"
                  ></path>
                  <path
                    d="M43.5 87C56.7548 87 67.5 76.2548 67.5 63C67.5 49.7451 56.7548 39 43.5 39C30.2452 39 19.5 49.7451 19.5 63C19.5 76.2548 30.2452 87 43.5 87Z"
                    fill="#FFC306"
                  ></path>
                  <path
                    d="M45 61.635V49.713C47.641 50.3949 49.4898 52.7724 49.5 55.5C49.5 56.3284 50.1716 57 51 57C51.8284 57 52.5 56.3284 52.5 55.5C52.4918 51.1131 49.3249 47.3698 45 46.635V45C45 44.1716 44.3284 43.5 43.5 43.5C42.6715 43.5 42 44.1716 42 45V46.635C37.6714 47.363 34.5014 51.1106 34.5014 55.5C34.5014 59.8894 37.6714 63.637 42 64.365V76.287C39.3589 75.6051 37.5102 73.2276 37.5 70.5C37.5 69.6716 36.8284 69 36 69C35.1715 69 34.5 69.6716 34.5 70.5C34.5081 74.8869 37.675 78.6302 42 79.365V81C42 81.8284 42.6715 82.5 43.5 82.5C44.3284 82.5 45 81.8284 45 81V79.365C49.3286 78.637 52.4985 74.8894 52.4985 70.5C52.4985 66.1106 49.3286 62.363 45 61.635V61.635ZM37.5 55.5C37.5102 52.7724 39.3589 50.3949 42 49.713V61.287C39.3589 60.6051 37.5102 58.2276 37.5 55.5V55.5ZM45 76.287V64.713C47.6463 65.3865 49.4986 67.7693 49.4986 70.5C49.4986 73.2307 47.6463 75.6134 45 76.287V76.287Z"
                    fill="#F49100"
                  ></path>
                  <path
                    d="M43.5 87C30.2452 87 19.5 76.2548 19.5 63C19.5 49.7451 30.2452 39 43.5 39C56.7548 39 67.5 49.7451 67.5 63C67.4843 76.2483 56.7483 86.9843 43.5 87ZM43.5 42C31.902 42 22.5 51.402 22.5 63C22.5 74.598 31.902 84 43.5 84C55.098 84 64.5 74.598 64.5 63C64.4876 51.4071 55.0928 42.0124 43.5 42Z"
                    fill="#F49100"
                  ></path>
                  <path
                    d="M79.5 10.62V31.905C78.5728 31.365 77.4271 31.365 76.5 31.905V10.17C76.4943 10.0262 76.5199 9.88291 76.575 9.74998L79.5 10.62Z"
                    fill="#FFC306"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_1_66">
                    <rect width="87" height="87" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
              <p>
                Donor Scholarship <br></br> Payments
              </p>
            </div>
            <div className="ml-[10rem] mb-[2rem] xxs:ml-0 md:mx-5">
              <svg
                className="border bg-white shadow-lg h-[10rem] w-[10rem] p-[2rem] rounded-[50%] cursor-pointer mx-auto border-[#f0e6e6] hover:h-[11.5rem] hover:w-[11.5rem] hover:p-[2.3rem] xxs:h-[6rem] xxs:w-[6rem] xxs:p-[1rem] xxs:hover:w-[6.5rem] xxs:hover:h-[6.5rem] xxs:hover:p-[1.2rem] xsm:h-[8rem] xsm:w-[8rem] xsm:p-[1.2rem] xsm:hover:w-[8.5rem] xsm:hover:h-[8.5rem] xsm:hover:p-[1.4rem] md:h-[5.5rem] md:w-[5.5rem] md:p-[1rem] md:hover:w-[6.5rem] md:hover:h-[6.5rem] md:hover:p-[1.2rem] lg:h-[7rem] lg:w-[7rem] lg:p-[1.2rem] lg:hover:w-[8rem] lg:hover:h-[8rem] lg:hover:p-[1.4rem] xl:h-[8rem] xl:w-[8rem] xl:p-[1.2rem] xl:hover:w-[9rem] xl:hover:h-[9rem] xl:hover:p-[1.6rem]          2xl:h-[9rem] 2xl:w-[9rem] 2xl:p-[1.3rem] 2xl:hover:w-[10rem] 2xl:hover:h-[10rem] 2xl:hover:p-[1.5rem]    "
                xmlns="http://www.w3.org/2000/svg"
                width="87"
                height="87"
                viewBox="0 0 87 87"
                fill="none"
              >
                <path
                  d="M48.2578 73.7461V78.1641L56.7539 77.4844V73.0664L48.2578 73.7461Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M48.2578 73.7461V78.1641L56.7539 77.4844V73.0664L48.2578 73.7461Z"
                  fill="#39BDBD"
                ></path>
                <path
                  d="M12.5742 72.7266V77.1445L18.0117 81.5625V77.1445L12.5742 72.7266Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M12.5742 72.7266V77.1445L18.0117 81.5625V77.1445L12.5742 72.7266Z"
                  fill="#39BDBD"
                ></path>
                <path
                  d="M6.45703 41.1211V45.5391L9.51562 53.3555V48.9375L6.45703 41.1211Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M6.45703 41.1211V45.5391L9.51562 53.3555V48.9375L6.45703 41.1211Z"
                  fill="#00537D"
                ></path>
                <path
                  d="M20.7305 50.2969V54.7148L27.1875 51.3164V46.8984L20.7305 50.2969Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M20.7305 50.2969V54.7148L27.1875 51.3164V46.8984L20.7305 50.2969Z"
                  fill="#00537D"
                ></path>
                <path
                  d="M66.9492 55.0547V59.4727L72.7266 62.8711V58.4531L66.9492 55.0547Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M66.9492 55.0547V59.4727L72.7266 62.8711V58.4531L66.9492 55.0547Z"
                  fill="#39BDBD"
                ></path>
                <path
                  d="M13.5938 22.7695V27.1875L21.1213 32.2954L23.1094 29.2266L13.5938 22.7695Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M13.5938 22.7695V27.1875L21.1213 32.2954L23.1094 29.2266L13.5938 22.7695Z"
                  fill="#00537D"
                ></path>
                <path
                  d="M68.1047 12.0033L68.9883 10.875V6.45703L65.25 11.2148L68.1047 12.0033Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M68.1047 12.0033L68.9883 10.875V6.45703L65.25 11.2148L68.1047 12.0033Z"
                  fill="#00537D"
                ></path>
                <path
                  d="M75.1497 26.467L78.1641 23.4492V19.0312L72.3867 24.8086L75.1497 26.467Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M75.1497 26.467L78.1641 23.4492V19.0312L72.3867 24.8086L75.1497 26.467Z"
                  fill="#00537D"
                ></path>
                <path
                  d="M72.7266 62.8711L79.5234 57.0938V52.6758L72.7266 58.4531V62.8711Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M72.7266 62.8711L79.5234 57.0938V52.6758L72.7266 58.4531V62.8711Z"
                  fill="#00537D"
                ></path>
                <path
                  d="M34.6641 58.1133V62.5312L36.0302 65.2602L37.7227 64.2305L34.6641 58.1133Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M34.6641 58.1133V62.5312L36.0302 65.2602L37.7227 64.2305L34.6641 58.1133Z"
                  fill="#00537D"
                ></path>
                <path
                  d="M82.9219 41.8008L76.125 45.8789L77.8242 49.2773L82.9219 46.2188V41.8008Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M82.9219 41.8008L76.125 45.8789L77.8242 49.2773L82.9219 46.2188V41.8008Z"
                  fill="#00537D"
                ></path>
                <path
                  d="M36.3633 82.582V87L48.2578 78.1641V73.7461L36.3633 82.582Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M36.3633 82.582V87L48.2578 78.1641V73.7461L36.3633 82.582Z"
                  fill="#00537D"
                ></path>
                <path
                  d="M18.0117 77.1445H29.5664V81.5625H18.0117V77.1445Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M18.0117 77.1445H29.5664V81.5625H18.0117V77.1445Z"
                  fill="#00537D"
                ></path>
                <path
                  d="M60.4922 61.1719L56.7539 73.0664V77.4844L60.4922 65.5898L66.9492 59.4727V55.0547L60.4922 61.1719Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M60.4922 61.1719L56.7539 73.0664V77.4844L60.4922 65.5898L66.9492 59.4727V55.0547L60.4922 61.1719Z"
                  fill="#00537D"
                ></path>
                <path
                  d="M29.5664 77.1445V81.5625L36.3633 87V82.582L29.5664 77.1445Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M29.5664 77.1445V81.5625L36.3633 87V82.582L29.5664 77.1445Z"
                  fill="#39BDBD"
                ></path>
                <path
                  d="M27.1875 46.8984V51.3164L36.4346 56.0708L39.082 53.0156L27.1875 46.8984Z"
                  fill="#39BDBD"
                ></path>
                <path
                  d="M9.51562 48.9375V53.3555L20.7305 54.7148V50.2969L9.51562 48.9375Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M9.51562 48.9375V53.3555L20.7305 54.7148V50.2969L9.51562 48.9375Z"
                  fill="#39BDBD"
                ></path>
                <path
                  d="M82.9219 41.8008L79.1836 28.8867L72.3867 24.8086L78.1641 19.0312L75.1055 13.9336L65.25 11.2148L68.9883 6.45703L61.5117 2.03906L47.918 6.45703L44.5195 12.5742L35.6836 10.5352L27.5273 19.7109L21.75 16.9922L13.5938 22.7695L23.1094 29.2266L19.3711 35.0039L11.5547 36.3633L6.45703 41.1211L9.51562 48.9375L20.7305 50.2969L27.1875 46.8984L39.082 53.0156L34.6641 58.1133L37.7227 64.2305L29.9062 68.9883L19.3711 66.9492L12.5742 72.7266L18.0117 77.1445H29.5664L36.3633 82.582L48.2578 73.7461L56.7539 73.0664L60.4922 61.1719L66.9492 55.0547L72.7266 58.4531L79.5234 52.6758L76.125 45.8789L82.9219 41.8008Z"
                  fill="#D9E021"
                ></path>
                <path
                  d="M82.9219 41.8008L79.1836 28.8867L72.3867 24.8086L78.1641 19.0312L75.1055 13.9336L65.25 11.2148L68.9883 6.45703L61.5117 2.03906L47.918 6.45703L44.5195 12.5742L35.6836 10.5352L27.5273 19.7109L21.75 16.9922L13.5938 22.7695L23.1094 29.2266L19.3711 35.0039L11.5547 36.3633L6.45703 41.1211L9.51562 48.9375L20.7305 50.2969L27.1875 46.8984L39.082 53.0156L34.6641 58.1133L37.7227 64.2305L29.9062 68.9883L19.3711 66.9492L12.5742 72.7266L18.0117 77.1445H29.5664L36.3633 82.582L48.2578 73.7461L56.7539 73.0664L60.4922 61.1719L66.9492 55.0547L72.7266 58.4531L79.5234 52.6758L76.125 45.8789L82.9219 41.8008Z"
                  fill="#FF9C1A"
                ></path>
                <path
                  d="M49.7871 51.3164C59.1716 51.3164 66.7793 46.4475 66.7793 40.4414C66.7793 34.4353 59.1716 29.5664 49.7871 29.5664C40.4026 29.5664 32.7949 34.4353 32.7949 40.4414C32.7949 46.4475 40.4026 51.3164 49.7871 51.3164Z"
                  fill="#E64E17"
                ></path>
                <path
                  d="M49.7871 48.5977C57.2947 48.5977 63.3809 44.7938 63.3809 40.1016C63.3809 35.4093 57.2947 31.6055 49.7871 31.6055C42.2795 31.6055 36.1934 35.4093 36.1934 40.1016C36.1934 44.7938 42.2795 48.5977 49.7871 48.5977Z"
                  fill="#ECFFD7"
                ></path>
                <path
                  d="M49.7871 46.2188C55.4178 46.2188 59.9824 43.4039 59.9824 39.9316C59.9824 36.4594 55.4178 33.6445 49.7871 33.6445C44.1564 33.6445 39.5918 36.4594 39.5918 39.9316C39.5918 43.4039 44.1564 46.2188 49.7871 46.2188Z"
                  fill="#E64E17"
                ></path>
                <path
                  d="M49.7871 43.8398C53.5409 43.8398 56.584 42.014 56.584 39.7617C56.584 37.5094 53.5409 35.6836 49.7871 35.6836C46.0333 35.6836 42.9902 37.5094 42.9902 39.7617C42.9902 42.014 46.0333 43.8398 49.7871 43.8398Z"
                  fill="#ECFFD7"
                ></path>
                <path
                  d="M52.6758 39.7617V1.69922L49.957 3.39844V41.4609L52.6758 39.7617Z"
                  fill="#00537D"
                ></path>
                <path
                  d="M49.957 3.39844L47.2383 1.69922V39.7617L49.957 41.4609V3.39844Z"
                  fill="#E64E17"
                ></path>
                <path
                  d="M49.957 7.81641V24.8086L72.7266 38.0625V20.3906L49.957 7.81641Z"
                  fill="#39BDBD"
                ></path>
                <path
                  d="M49.957 7.81641L52.6758 6.45703L75.1055 19.0312L72.7266 20.3906L49.957 7.81641Z"
                  fill="#48D7D9"
                ></path>
                <path
                  d="M75.1055 19.0312V36.7031L72.7266 38.0625V20.3906L75.1055 19.0312Z"
                  fill="#00537D"
                ></path>
                <path
                  d="M66.6094 24.4688L63.8906 22.7695V19.0312L58.1133 15.6328V19.7109L55.3945 18.0117V25.1484L59.4727 27.4968V23.7891L61.8516 25.1484V28.8663L66.6094 31.6055V24.4688Z"
                  fill="#ECFFD7"
                ></path>
                <path
                  d="M47.2383 1.69922L49.957 0L52.6758 1.69922L49.957 3.39844L47.2383 1.69922Z"
                  fill="#FF9C1A"
                ></path>
              </svg>
              <p>
                Pay Fees to <br></br> US, UK & Canada
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="sectFour px-32 py-10 xxs:px-10 xxs:py-3 md:px-8 lg:px-20">
        <h1 className="text-center text-[2.3rem] mb-4 xxs:text-[1.4rem] xxs:mb-2 xsm:text-[1.8rem]">
          Corporate Partners
        </h1>
        <Partners />
      </div>
    </div>
  );
}

export default Home;
