import React from "react";
import my_acc_tab from "../../images/my_acc_tab.jpg";
import confirm_deletion_tab from "../../images/confirm_deletion_tab.jpg";
import delete_tab from "../../images/delete_tab.jpg";

function Account() {
  return (
    <div className="w-full py-6 px-52 xxs:py-2 xxs:px-3 xsm:px-4 sm:px-9 md:px-16 lg:px-32 lg:py-4 xl:px-44 2xl:px-56 ">
      <h2 className="text-blue-900 text-[2rem] my-4 border-b-2 border-blue-700 font-semibold xxs:text-[1.3rem] sm:text-[1.5rem] lg:text-[1.7rem] xl:text-[2rem]">
        Sponsor User Account
      </h2>

      <h4 className="text-blue-600 font-bold text-[1.5rem] xxs:text-[1.2rem] sm:text-[1.4rem] lg:text-[1.5rem]">
        Definition
      </h4>

      <p className="mb-3">
        Your account refers to a unique access session created for you to access
        our services as a Skool Pesa sponsor user.
      </p>

      <h4 className="text-blue-600 font-bold text-[1.5rem] xxs:text-[1.2rem] sm:text-[1.4rem] lg:text-[1.5rem]">
        Account Services
      </h4>
      <div className="my-1">
        <ul className="ml-5 list-disc">
          <li>Linking beneficiaries.</li>
          <li>Viewing beneficiary fee invoices.</li>
          <li>Making school fees payments.</li>
          <li>Accessing payments and fee receipts.</li>
        </ul>
      </div>

      <h4 className="text-blue-600 font-bold text-[1.5rem] xxs:text-[1.2rem] sm:text-[1.4rem] lg:text-[1.5rem]">
        Account Policies and Scope
      </h4>
      <p className="my-1">
        Your use of the account and services is governed by these
        <a
          href="https://www.skoolpesa.com/privacy-policy"
          className="text-blue-600"
        >
          {" "}
          Policies{" "}
        </a>
        and is scoped as in these{" "}
        <a
          href="https://www.skoolpesa.com/terms-and-conditions"
          className="text-blue-600"
        >
          Terms and Conditions.
        </a>
      </p>

      <h4 className="text-blue-600 font-bold text-[1.5rem] xxs:text-[1.2rem] sm:text-[1.4rem] lg:text-[1.5rem]">
        Account Data
      </h4>
      <p className="my-1">
        Your account is associated with the following data, provided as part of
        the onboarding process; <br />
        <ul className="my-1 ml-5 list-disc">
          <li>Email address </li>
          <li>Name</li>
          <li>Phone number</li>
          <li>Address, State, Province, ZIP/Postal code, City</li>
        </ul>
        And usage Data accrued due to application use, that includes:
        <ul className="my-1 ml-5 list-disc">
          <li>Platform Notifications</li>
          <li>Transaction logs</li>
          <li> Payment Receipts</li>
          <li>Linked Beneficiaries</li>
          <li>
            {" "}
            Payment methods and history data(exclusive of card details used)
          </li>
        </ul>
      </p>
      <h4 className="text-red-700 font-bold text-[1.5rem] xxs:text-[1.2rem] sm:text-[1.4rem] lg:text-[1.5rem]">
        Account Deletion
      </h4>

      <p className="my-1">
        Deleting your account shall render you unable to log in, utilize our
        services and access any of your data, as such data shall be purged from
        our servers and your access rescinded as a result. <br />
        To delete an account, proceed as follows:
        <ol className="list-decimal ml-5">
          <li>
            Using the mobile app, click on{" "}
            <em className="text-blue-700">"My account"</em> - This should be on
            the bottom right of your screen.
          </li>
          <img
            src={my_acc_tab}
            alt="acc tab"
            className="m-1 w-full max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg"
          ></img>
          <li>
            On the <em className="text-blue-700">"My account"</em> screen, click
            the tab <em className="text-blue-700">"Delete Account"</em>.
          </li>
          <img
            src={delete_tab}
            alt="delete tab"
            className="m-1 w-full max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg"
          ></img>

          <li>
            Click on <span className="text-red-700">"Yes"</span> to confirm
            account deletion
          </li>
          <img
            src={confirm_deletion_tab}
            alt="confirm_delete"
            className="m-1 w-full max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg"
          ></img>
        </ol>{" "}
      </p>

      <h2 className="text-blue-900 text-[2rem] my-1 border-b-2 border-blue-700 font-semibold xxs:text-[1.3rem] sm:text-[1.5rem] lg:text-[1.7rem] xl:text-[2rem]"></h2>
      <h4 className="text-blue-900 font-bold text-[1.5rem] xxs:text-[1.2rem] sm:text-[1.4rem] lg:text-[1.5rem]">
        Additional Support on Account
      </h4>

      <p className="my-3">
        In case of any issues or additional requests with your account, please
        reach out to us via the following support channels:
      </p>

      <ul className="my-3 ml-5 list-disc">
        <li className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5 mr-2 text-blue-600"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.75 4.5v15a1.5 1.5 0 01-1.5 1.5h-15a1.5 1.5 0 01-1.5-1.5v-15a1.5 1.5 0 011.5-1.5h15a1.5 1.5 0 011.5 1.5z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 7.5l8.25 6.75L20.25 7.5"
            />
          </svg>
          <a
            href="mailto:support@skoolpesa.com"
            className="text-blue-600 hover:underline"
          >
            support@skoolpesa.com
          </a>
        </li>
        <li className="flex items-center mt-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5 mr-2 text-green-700"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 3.75a16.5 16.5 0 0116.5 16.5M3.75 3.75l2.25 7.5M3.75 3.75l7.5 2.25"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 13.5a3 3 0 006 0v-3a3 3 0 00-6 0v3z"
            />
          </svg>
          <a
            href="tel:+254706520652"
            className="text-green-700 hover:underline"
          >
            +254 741 243 915
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Account;
