import React from "react";
import About from "../components/about/About";
import Footer from "../components/footer/Footer";

function AboutPage() {
  return (
    <div>
      <About />
      <Footer />
    </div>
  );
}

export default AboutPage;
