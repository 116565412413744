import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo-300x51.png";
import prepaid from "../../images/credit-cards-1.png";
import fees from "../../images/Group-1-1.png";
import lending from "../../images/peer-to-peer-1.png";
import storeImage from "../../images/store-1.png";

function Services() {
  const [toggle, setToggle] = useState(false);

  const handleClick = () => {
    setToggle(!toggle);
  };

  return (
    <div>
      <div className="homepage bg-serviceImage bg-cover py-10 px-32 h-[380px] xxs:h-[60px] xsm:h-[70px] xxs:py-4 xxs:px-6  xsm:py-6 xsm:px-8 md:py-3 lg:pt-6  lg:px-16 xl:px-28  lg:pt-8 lg:h-[300px] xl:h-[320px] 2xl:h-[380px]">
        <div className="sectOne flex xxs:justify-between">
          {!toggle ? (
            <Link to="/">
              <img
                src={logo}
                alt="skoolpesa logo"
                className="cursor-pointer text-[1.3rem] xxs:w-[10rem] md:w-[9rem] lg:w-[11rem] xl:w-[12rem] 2xl:w-[13rem]  "
              ></img>{" "}
            </Link>
          ) : (
            <></>
          )}
          <div className="navbar flex justify-between ml-[5rem] text-[1.1rem] w-full xxs:hidden md:flex md:text-[0.4rem] md:ml-6 lg:ml-[2rem] lg:text-[0.5rem] xl:text-[0.7rem] 2xl:text-[1rem]">
            <div className="navigation text-white mt-[1rem] md:flex">
              <Link
                to="/"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Home
              </Link>
              <Link
                to="/about-us"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                About us
              </Link>
              <Link
                to="/services"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Services
              </Link>
              <Link
                to="/why-skool-pesa"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Why Skool Pesa
              </Link>
              <Link
                to="/faqs"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                FAQs
              </Link>
              <Link
                to="/contact"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Contact Us
              </Link>
            </div>
            <Link
              to="/sign-up"
              className="my-auto text-white text-[1rem] bg-[#162C4A] rounded-lg px-9 py-4 hover:bg-white hover:text-[#162C4A] hover:border-1 hover:border-[#162C4A] md:text-[0.4rem] md:px-3 md:py-1 md:rounded-[3px] md:my-auto lg:text-[0.4rem] xl:text-[0.7rem] 2xl:text-[1rem] 2xl:px-6 2xl:py-2 2xl:rounded-lg"
            >
              Sign Up Now
            </Link>
          </div>

          <div className="flex ">
            {toggle ? (
              <div className="z-50 md:hidden xl:hidden 2xl:hidden bg-[#162C4A] xxs:w-[15rem] xsm:w-[17rem] sm:w-[19rem] grid sm:p-3 xxs:h-[24rem] sm:h-[24rem] xxs:ml-[-1rem] xsm:ml-[rem] sm:ml-[rem] xxs:text-white sm:text-white xxs:pb-24 xxs:p-2 sm:pb-28 ">
                <Link to="/" className="hover:text-[#162C4A]">
                  Home
                </Link>
                <Link to="/about-us" className="hover:text-[#162C4A]">
                  About us
                </Link>
                <Link to="/services" className="hover:text-[#162C4A]">
                  Services
                </Link>
                <Link to="/why-skool-pesa" className="hover:text-[#162C4A]">
                  Why Skool Pesa
                </Link>
                <Link to="/faqs" className="hover:text-[#162C4A]">
                  FAQs
                </Link>
                <Link to="/contact" className="hover:text-[#162C4A]">
                  Contact Us
                </Link>
                <Link to="/sign-up" className="hover:text-[#162C4A]">
                  Signup
                </Link>
                {/* <input placeholder="Search" className="rounded-[20rem] px-3" /> */}
              </div>
            ) : (
              <></>
            )}
          </div>
          {!toggle ? (
            <i
              className="fa fa-bars text-[#162C4A] border border-blue-400 py-1 px-1 rounded-sm bg-blue-400 xxs:visible xxs:text-white md:hidden xl:hidden 2xl:hidden h-6"
              onClick={handleClick}
            ></i>
          ) : (
            <i
              className="fa fa-times text-[#162C4A] border border-[#162C4A] py-1 px-2 rounded-sm bg-[#162C4A] xxs:visible xxs:text-white md:hidden xl:hidden 2xl:hidden h-6"
              onClick={handleClick}
            ></i>
          )}
        </div>
      </div>
      <div className="py-[3rem]">
        <div className="border-t-[2px] border-b-[1px] border-gray-400 py-10 px-20 flex justify-between xxs:px-2 xxs:block xsm:px-8 md:px-12 lg:flex lg:px-20 md:flex  xl:px-32 ">
          <div className="xxs:flex lg:block xxs:justify-between xxs:w-full md:block  md:w-[60%] ">
            <img src={fees} alt="School fees" className="mb-[1rem]"></img>
            <p className="text-[1.4rem] font-semibold leading-6 xxs:m-auto lg:m-0 xxs:text-[1.1rem] xsm:text-[1.2rem]  ">
              School Fees Payments
            </p>
          </div>
          <p className="my-auto xxs:text-[0.9rem]  xsm:text-[1rem] ">
            This is our core service where we enable payment of school fees from
            any channel. You can download our App from Google Playstore or Apple
            App Store.
          </p>
        </div>
        <div className="border-t-[1px] border-b-[1px] border-gray-400 py-10 px-20 flex justify-between xxs:px-2 xxs:block xsm:px-8 md:px-12 lg:flex lg:justify-between lg:px-20 md:flex  xl:px-32">
          <div className="xxs:flex lg:block xxs:justify-between xxs:w-full md:block md:w-[90%]  ">
            <img src={storeImage} alt="Store" className="mb-[0.8rem]"></img>
            <p className="text-[1.4rem] font-semibold xxs:m-auto xxs:text-[1.1rem]  xsm:text-[1.2rem] ">
              Marketplace
            </p>
          </div>
          <p className="my-auto xxs:text-[0.9rem] xsm:text-[1rem] ">
            The Marketplace will be focused on items related to educational
            institutions. Only verified vendors will be allowed on our platform.
            If you are a vendor interested to be on our marketplace please send
            an email to{" "}
            <a className="text-blue-600" href="mailto: sales@skoolpesa.com">
              sales@skoolpesa.com
            </a>
          </p>
        </div>

        <div className="border-t-[1px] border-b-[1px] border-gray-400 py-10 px-20 flex justify-between xxs:px-2 xxs:block xsm:px-8 md:px-12 lg:px-20 lg:flex lg:justify-between md:flex md:w-[100%]   xl:px-32 ">
          <div className="xxs:flex lg:block xxs:justify-between xxs:w-full md:block md:w-[85%]  ">
            <img src={prepaid} alt="cards" className="mb-[0.8rem]"></img>
            <p className="text-[1.4rem] font-semibold xxs:m-auto xxs:text-[1.1rem]  xsm:text-[1.2rem] ">
              Prepaid Cards
            </p>
          </div>
          <p className="my-auto xxs:text-[0.9rem] xsm:text-[1rem] ">
            Sponsors/parents will be able to add a prepaid visa debit card for
            their beneficiaries. The sponsor/parent can add funds to the prepaid
            card and track spending. Funds can be "pocket money" for usage on
            incidentals.
          </p>
        </div>

        <div className="border-t-[1px] border-b-[2px] border-gray-400 py-10 px-20 flex justify-between xxs:px-2 xxs:block xsm:px-8 md:px-12 lg:px-20 lg:justify-between lg:flex md:flex xl:px-32">
          <div className="xxs:flex xxs:justify-between lg:block xxs:w-full md:block md:w-[75%]    ">
            <img src={lending} alt="lending" className="mb-[0.8rem]"></img>
            <p className="text-[1.4rem] font-semibold xxs:m-auto xxs:text-[1.1rem]  xsm:text-[1.2rem] ">
              Lending
            </p>
          </div>
          <p className="my-auto xxs:text-[0.9rem] xsm:text-[1rem] ">
            Our lending is specific to school fees. Sponsors/parents can borrow
            funds which will be added to their wallet upon approval and can{" "}
            <span>only</span> be used to pay outstanding school fees invoices.
          </p>
        </div>
      </div>

      <div className="bg-kidsImage bg-cover py-[4rem] pb-[6rem] xxs:py-5 xsm:px-6 md:px-2 lg:px-8">
        <p className="text-[3rem] font-semibold text-white text-center mb-[3rem] xxs:mb-0 xxs:text-[1.5rem]  xsm:text-[1.7rem] ">
          Modules
        </p>
        <div className="flex justify-center xxs:block xxs:px-2 md:flex xl:px-40">
          <div className="border p-8 rounded-lg mx-[1rem] bg-white text-[1rem] xxs:p-4 xxs:mx-auto xxs:my-2 md:mx-2 xl:w-[44rem]   ">
            <p className="text-[1.3rem] font-semibold mb-[1.5rem] xxs:mb-1 xxs:text-[1.1rem]  xsm:text-[1.2rem] ">
              Enrolment Module
            </p>
            <p className="mb-[2rem] xxs:mb-2 xxs:text-[0.8rem] xsm:text-[0.9rem] ">
              The admissions module is the registration intake piece for new
              students. The school can easily register a student and create a
              permanent record with a unique ID number. This is an important
              component because Skool Pesa uses this to integrate with the
              payments systems and identify where the school fees should go.
              Some of the feature of this module include:
            </p>
            <ul className="ml-10 xxs:text-[0.8rem] xsm:text-[0.9rem] ">
              <li className="list-disc">Unique ID for all students</li>
              <li className="list-disc">Comprehensive admission form</li>
              <li className="list-disc">Multiple guardian addition facility</li>
              <li className="list-disc">
                Customizable as per school standards
              </li>
              <li className="list-disc">Photo upload facility</li>
            </ul>
          </div>
          <div className="border p-8 rounded-lg mx-[1rem] bg-white xxs:p-4 xxs:mx-auto xxs:my-4 md:mx-2 xl:w-[44rem]   ">
            <p className="text-[1.3rem] font-semibold mb-[1.5rem] xxs:mb-1 xxs:text-[1.1rem] xsm:text-[1.2rem] ">
              Payment Module
            </p>
            <p className="mb-[2rem] xxs:mb-2 xxs:text-[0.8rem] xsm:text-[0.9rem] ">
              This Module allows the school or educational institution to
              request payments from parents, track defaulters, acknowledge
              receipt of payment and manage your cash flow. Our value
              proposition lies in the fact that there is a one to one
              correlation between payment made and student. Some of the feature
              of this module include:
            </p>
            <ul className="ml-10 xxs:text-[0.8rem] xsm:text-[0.9rem] ">
              <li className="list-disc">
                Separate fee collection date designing system for faster finance
                process
              </li>
              <li className="list-disc">
                Easy analysis of fee defaulters and fee structure available
              </li>
              <li className="list-disc">Easy fee submission process</li>
              <li className="list-disc">Manage and Pay instant fees</li>
              <li className="list-disc">POS to accept cash</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="px-[28rem] py-10 xxs:py-2 xxs:px-3 xsm:px-8 md:py-5 md:px-16 lg:px-20 2xl:px-[28rem] sm:px-20 md:px-24 lg:px-32 xl:px-52 ">
        <p className="text-[2.3rem] font-semibold xxs:text-center xxs:text-[1.4rem]  ">
          Other Modules
        </p>
        <p className="xxs:text-[0.8rem] xsm:text-[0.9rem] ">
          Skool Pesa will make other modules available upon request which will
          be billed on a time and material basis. For now we are focused to
          provide a service that makes fees payment simple for parents and
          educational institutions. If your institutions have unique
          requirements beyond our current offerings please feel free to contact
          us.
        </p>
      </div>
    </div>
  );
}

export default Services;
