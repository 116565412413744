import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo-300x51.png";

function Faqs() {
  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);
  const [toggle5, setToggle5] = useState(false);
  const [toggle6, setToggle6] = useState(false);
  const [toggle7, setToggle7] = useState(false);
  const [toggle9, setToggle9] = useState(false);

  const handleClick9 = () => {
    setToggle9(!toggle9);
  };

  const handleClick = () => {
    setToggle(!toggle);
    setToggle1(false);
    setToggle2(false);
    setToggle3(false);
    setToggle4(false);
    setToggle5(false);
    setToggle6(false);
    setToggle7(false);
  };

  const handleClick1 = () => {
    setToggle1(!toggle1);
    setToggle(false);
    setToggle2(false);
    setToggle3(false);
    setToggle4(false);
    setToggle5(false);
    setToggle6(false);
    setToggle7(false);
  };

  const handleClick2 = () => {
    setToggle2(!toggle2);
    setToggle(false);
    setToggle1(false);
    setToggle3(false);
    setToggle4(false);
    setToggle5(false);
    setToggle6(false);
    setToggle7(false);
  };

  const handleClick3 = () => {
    setToggle3(!toggle3);
    setToggle(false);
    setToggle1(false);
    setToggle2(false);
    setToggle4(false);
    setToggle5(false);
    setToggle6(false);
    setToggle7(false);
  };

  const handleClick4 = () => {
    setToggle4(!toggle4);
    setToggle(false);
    setToggle2(false);
    setToggle3(false);
    setToggle1(false);
    setToggle5(false);
    setToggle6(false);
    setToggle7(false);
  };

  const handleClick5 = () => {
    setToggle5(!toggle5);
    setToggle(false);
    setToggle2(false);
    setToggle3(false);
    setToggle4(false);
    setToggle1(false);
    setToggle6(false);
    setToggle7(false);
  };

  const handleClick6 = () => {
    setToggle6(!toggle6);
    setToggle(false);
    setToggle2(false);
    setToggle3(false);
    setToggle4(false);
    setToggle5(false);
    setToggle1(false);
    setToggle7(false);
  };

  const handleClick7 = () => {
    setToggle7(!toggle7);
    setToggle(false);
    setToggle2(false);
    setToggle3(false);
    setToggle4(false);
    setToggle5(false);
    setToggle6(false);
    setToggle1(false);
  };

  return (
    <div>
      <div className="homepage bg-faqsImage bg-cover py-10 px-32 h-[380px] xxs:py-4 xxs:px-6  xxs:h-[60px] xsm:h-[70px] xsm:py-6 xsm:px-8 md:py-3 lg:pt-6  lg:px-16 xl:px-28  lg:pt-8 lg:h-[300px] xl:h-[320px] 2xl:h-[380px]">
        <div className="sectOne flex xxs:justify-between">
          {!toggle9 ? (
            <Link to="/">
              <img
                src={logo}
                alt="skoolpesa logo"
                className="cursor-pointer text-[1.3rem] xxs:w-[10rem] md:w-[9rem] lg:w-[11rem] xl:w-[12rem] 2xl:w-[13rem] "
              ></img>
            </Link>
          ) : (
            <></>
          )}
          <div className="navbar flex justify-between ml-[5rem] text-[1.1rem] w-full xxs:hidden md:flex md:text-[0.4rem] md:ml-6 lg:ml-[2rem] lg:text-[0.5rem] xl:text-[0.7rem] 2xl:text-[1rem]">
            <div className="navigation text-white mt-[1rem] md:flex">
              <Link
                to="/"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Home
              </Link>
              <Link
                to="/about-us"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                About us
              </Link>
              <Link
                to="/services"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Services
              </Link>
              <Link
                to="/why-skool-pesa"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Why Skool Pesa
              </Link>
              <Link
                to="/faqs"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                FAQs
              </Link>
              <Link
                to="/contact"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Contact Us
              </Link>
            </div>
            <Link
              to="/sign-up"
              className="my-auto text-white text-[1rem] bg-[#162C4A] rounded-lg px-9 py-4 hover:bg-white hover:text-[#162C4A] hover:border-1 hover:border-[#162C4A] md:text-[0.4rem] md:px-3 md:py-1 md:rounded-[3px] md:my-auto lg:text-[0.4rem] xl:text-[0.7rem] 2xl:text-[1rem] 2xl:px-6 2xl:py-2 2xl:rounded-lg"
            >
              Sign Up Now
            </Link>
          </div>

          <div className="flex ">
            {toggle9 ? (
              <div className="z-50 md:hidden xl:hidden 2xl:hidden bg-[#162C4A] xxs:w-[15rem] xsm:w-[17rem] sm:w-[19rem] grid sm:p-3 xxs:h-[24rem] sm:h-[24rem] xxs:ml-[-1rem] xsm:ml-[rem] sm:ml-[rem] xxs:text-white sm:text-white xxs:pb-24 xxs:p-2 sm:pb-28 ">
                <Link to="/" className="hover:text-[#162C4A]">
                  Home
                </Link>
                <Link to="/about-us" className="hover:text-[#162C4A]">
                  About us
                </Link>
                <Link to="/services" className="hover:text-[#162C4A]">
                  Services
                </Link>
                <Link to="/why-skool-pesa" className="hover:text-[#162C4A]">
                  Why Skool Pesa
                </Link>
                <Link to="/faqs" className="hover:text-[#162C4A]">
                  FAQs
                </Link>
                <Link to="/contact" className="hover:text-[#162C4A]">
                  Contact Us
                </Link>
                <Link to="/sign-up" className="hover:text-[#162C4A]">
                  Signup
                </Link>
                {/* <input placeholder="Search" className="rounded-[20rem] px-3" /> */}
              </div>
            ) : (
              <></>
            )}
          </div>
          {!toggle9 ? (
            <i
              className="fa fa-bars text-[#162C4A] border border-blue-400 py-1 px-1 rounded-sm bg-blue-400 xxs:visible xxs:text-white md:hidden xl:hidden 2xl:hidden h-6"
              onClick={handleClick9}
            ></i>
          ) : (
            <i
              className="fa fa-times text-[#162C4A] border border-[#162C4A] py-1 px-2 rounded-sm bg-[#162C4A] xxs:visible xxs:text-white md:hidden xl:hidden 2xl:hidden h-6"
              onClick={handleClick9}
            ></i>
          )}
        </div>
      </div>

      <div className="px-48 py-10 xxs:px-4 xsm:px-8 xxs:py-5 xsm:py-6 sm:px-10 md:px-20 lg:px-28 xl:px-40 2xl:px-48">
        <p className="text-[3rem] font-bold xxs:text-[1.2rem] xsm:text-[1.4rem] sm:text-[1.6rem] md:text-[1.8rem] lg:text-[2.2rem] xl:text-[2.3rem] 2xl:text-[2.6rem]">
          Frequently Asked Questions
        </p>
        <div className="my-4">
          <div className="flex justify-between">
            <p className="text-[1.3rem] font-semibold xxs:text-[1rem] xl:text-[1.2rem] 2xl:text-[1.3rem] ">
              What is Skool Pesa?
            </p>
            {!toggle ? (
              <button onClick={handleClick} className="xxs:text-[1.4rem]">
                +
              </button>
            ) : (
              <button onClick={handleClick} className="xxs:text-[1.5rem] mt-0">
                -
              </button>
            )}
          </div>
          {toggle ? (
            <p className="font-light text-gray-500 xxs:text-[0.9rem]">
              Skool Pesa is a cloud based fees payment and management gateway
              for educational institutions. Skool Pesa aggregates payment
              channels to make it easy for parents to pay school fees and for
              schools to easily manage school fees. Our platform sends real time
              confirmation to the school and parent once the fees are paid.
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="my-4">
          <div className="flex justify-between">
            <p className="text-[1.3rem] font-semibold xxs:text-[1rem] xl:text-[1.2rem] 2xl:text-[1.3rem] ">
              How do I register my institution?
            </p>
            {!toggle1 ? (
              <button onClick={handleClick1} className="xxs:text-[1.4rem]">
                +
              </button>
            ) : (
              <button onClick={handleClick1} className="xxs:text-[1.5rem] mt-0">
                -
              </button>
            )}
          </div>
          {toggle1 ? (
            <p className="font-light text-gray-500 xxs:text-[0.9rem]">
              It is easy to register with Skool Pesa - you can email us at{" "}
              <a className="text-blue-600" href="mailto: sales@skoolpesa.com">
                sales@skoolpesa.com
              </a>{" "}
              or fill out the online form (should be a hyperlink to intake form
              integrated to Zapier). One of our Sales Team member will visit you
              to set up the system and provide training. Your institution will
              receive a unique ID number and each student you admit will also
              receive a unique ID number which will make school fees payment
              process simple.
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="my-4">
          <div className="flex justify-between">
            <p className="text-[1.3rem] font-semibold xxs:text-[1rem] xl:text-[1.2rem] 2xl:text-[1.3rem]">
              What does cloud based mean?
            </p>
            {!toggle2 ? (
              <button onClick={handleClick2} className="xxs:text-[1.4rem]">
                +
              </button>
            ) : (
              <button onClick={handleClick2} className="xxs:text-[1.5rem] mt-0">
                -
              </button>
            )}
          </div>
          {toggle2 ? (
            <p className="font-light text-gray-500 xxs:text-[0.9rem]">
              Cloud based simply means that the platform is live on internet and
              can be accessed on demand. The end user does not need special
              hardware to host the application. The model saves your institution
              money on hardware, updates and technical support.
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="my-4">
          <div className="flex justify-between">
            <p className="text-[1.3rem] font-semibold xxs:text-[1rem] xl:text-[1.2rem] 2xl:text-[1.3rem]">
              How can parents pay school fees?
            </p>
            {!toggle3 ? (
              <button onClick={handleClick3} className="xxs:text-[1.4rem]">
                +
              </button>
            ) : (
              <button onClick={handleClick3} className="xxs:text-[1.5rem] mt-0">
                -
              </button>
            )}
          </div>
          {toggle3 ? (
            <p className="font-light text-gray-500 xxs:text-[0.9rem]">
              This is where the beauty of Skool Pesa and value proposition lies
              - the parent only need to have the unique school ID number and the
              student ID number. The parent can then choose to pay using Mpesa,
              Tigo Pesa, Airtel Money or visit one of Selcom's vendors. If you
              are using mobile money, you simply dial the appropriate short
              code, input the school ID and student ID and amount to be paid. If
              you are using Selcom POS, just give the vendor the school ID and
              student ID plus amount to be paid. In both instances you will
              receive instant confirmation via SMS.
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="my-4">
          <div className="flex justify-between">
            <p className="text-[1.3rem] font-semibold xxs:text-[1rem] xl:text-[1.2rem] 2xl:text-[1.3rem]">
              How much does it cost to send/pay school fees?
            </p>
            {!toggle4 ? (
              <button onClick={handleClick4} className="xxs:text-[1.4rem]">
                +
              </button>
            ) : (
              <button onClick={handleClick4} className="xxs:text-[1.5rem] mt-0">
                -
              </button>
            )}
          </div>
          {toggle4 ? (
            <p className="font-light text-gray-500 xxs:text-[0.9rem]">
              For all this convenience, Skool Pesa only charges a minimal fee on
              a per transaction basis. The fees is transparently displayed on
              your App during checkout. Fees vary by transaction and country.
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="my-4">
          <div className="flex justify-between">
            <p className="text-[1.3rem] font-semibold xxs:text-[1rem] xl:text-[1.2rem] 2xl:text-[1.3rem]">
              How much does Skool Pesa platform cost?
            </p>
            {!toggle5 ? (
              <button onClick={handleClick5} className="xxs:text-[1.4rem]">
                +
              </button>
            ) : (
              <button onClick={handleClick5} className="xxs:text-[1.5rem] mt-0">
                -
              </button>
            )}
          </div>
          {toggle5 ? (
            <p className="font-light text-gray-500 xxs:text-[0.9rem]">
              The Skool Pesa platform is free to educational institution i.e the
              Enrolment Module and Payments Module. All we ask in return is an
              agreement for Skool Pesa to process school fees payments for your
              institution on a non-exclusive basis. For each institution that
              opts to sign an exclusive agreement with Skool Pesa, we will open
              a special account where a fraction of the transaction fees are
              shared to be used to sponsor needy students or climate change
              projects like tree planting. We are also developing an agent model
              where Educational institutions with an exclusive agreement with
              Skool Pesa can beome an "agent" and earn extra revenues.
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="my-4">
          <div className="flex justify-between">
            <p className="text-[1.3rem] font-semibold xxs:text-[1rem] xl:text-[1.2rem] 2xl:text-[1.3rem]">
              Do you provide training?
            </p>
            {!toggle6 ? (
              <button onClick={handleClick6} className="xxs:text-[1.4rem]">
                +
              </button>
            ) : (
              <button onClick={handleClick6} className="xxs:text-[1.5rem] mt-0">
                -
              </button>
            )}
          </div>
          {toggle6 ? (
            <p className="font-light text-gray-500 xxs:text-[0.9rem]">
              Yes - our Sales Team will come to your school to do the initial
              set-up and also provide ample training. We will also be available
              to any field questions and also provide initial data entry
              services for free.
            </p>
          ) : (
            <></>
          )}
        </div>

        <div className="my-4">
          <div className="flex justify-between">
            <p className="text-[1.3rem] font-semibold xxs:text-[1rem] xl:text-[1.2rem] 2xl:text-[1.3rem]">
              Do you Provide Technical Support?
            </p>
            {!toggle7 ? (
              <button onClick={handleClick7} className="xxs:text-[1.4rem]">
                +
              </button>
            ) : (
              <button onClick={handleClick7} className="xxs:text-[1.5rem] mt-0">
                -
              </button>
            )}
          </div>
          {toggle7 ? (
            <p className="font-light text-gray-500 xxs:text-[0.9rem]">
              Yes - Skool Pesa will provide technical support.
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default Faqs;
