import React from "react";
import pesa from "../../images/logo-300x51.png";
import iosImage from "../../images/logoo-300x90.png";
import androidImage from "../../images/imgpsh_fullsize_anim-300x88.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bottom bg-[#02182B] px-32 pt-[5rem] pb-4 text-white xxs:text-center xxs:p-1 xxs:pt-[0.1rem] xsm:text-center xsm:p-2 xsm:pt-[0.2rem] sm:pb-0 md:px-12 md:pt-[1rem] lg:pt-[2rem] lg:px-24 xl:pt-4 xl:px-28 2xl:px-32">
      <div className="support flex justify-between xxs:block xxs:pt-2 xsm:pt-2 xsm: md:flex md:flex-row lg:flex lg:flex-row xl:flex xl:flex-row 2xl:flex 2xl:flex-row">
        <div className="contact flex xxs:flex xxs:justify-between xxs:px-10 xsm:flex xsm:justify-between xsm:px-20 sm:flex sm:px-32 md:flex md:flex-row md:text-start lg:flex lg:flex-row xl:flex xl:flex-row 2xl:flex-row lg:text-start xl:text-start 2xl:text-start md:pl-0">
          <div className="grid xxs:my-2 xsm:my-0 text-start">
            <h2 className="text-[2rem] xxs:text-[1.2rem] xsm:text-[1.4rem] md:text-[1.3rem] lg:text-[1.4rem]">
              Support
            </h2>
            <Link
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
              to="/about-us"
              className="cursor-pointer hover:text-[#162C4A] text-[1.3rem] xxs:text-[0.8rem] xsm:text-[1rem] md:text-[0.9rem] lg:text-[1rem]"
            >
              About
            </Link>
            <Link
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
              className="cursor-pointer hover:text-[#162C4A] text-[1.3rem] xxs:text-[0.8rem] xsm:text-[1rem] md:text-[0.9rem] lg:text-[1rem] "
            >
              Careers
            </Link>
            <Link
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
              to="/services"
              className="cursor-pointer hover:text-[#162C4A] text-[1.3rem] xxs:text-[0.8rem] xsm:text-[1rem] md:text-[0.9rem] lg:text-[1rem] "
            >
              Services
            </Link>
            <Link
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
              to="/faqs"
              className="cursor-pointer hover:text-[#162C4A] text-[1.3rem] xxs:text-[0.8rem] xsm:text-[1rem] md:text-[0.9rem] lg:text-[1rem] "
            >
              FAQs
            </Link>
            <Link
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
              to="/contact"
              className="cursor-pointer hover:text-[#162C4A] text-[1.3rem] xxs:text-[0.8rem] xsm:text-[1rem] md:text-[0.9rem] lg:text-[1rem] "
            >
              Contact us
            </Link>
          </div>
          <div className="finserve grid xxs:my-2 xsm:my-0 ml-[10rem] xxs:ml-0 text-start xsm:ml-0 md:mt-0 md:ml-[2rem] lg:mt-0 xl:mt-0 2xl:mt-0 lg:ml-[3rem] ">
            <h2 className="text-[2rem] xxs:text-[1.2rem] xsm:text-[1.4rem] md:text-[1.3rem] lg:text-[1.4rem] ">
              Partners
            </h2>
            <Link
              className="cursor-pointer hover:text-[#162C4A] text-[1.3rem] xxs:text-[0.8rem] xsm:text-[1rem] md:text-[0.9rem] lg:text-[1rem] "
              to="https://www.selcom.net/about-us"
              target="_blank"
            >
              Selcom
            </Link>
            <Link
              className="cursor-pointer hover:text-[#162C4A] text-[1.3rem] xxs:text-[0.8rem] xsm:text-[1rem] md:text-[0.9rem] lg:text-[1rem]"
              to="https://www.finserve.africa/"
              target="_blank"
            >
              Finserve
            </Link>
            <Link
              className="cursor-pointer hover:text-[#162C4A] text-[1.3rem] xxs:text-[0.8rem] xsm:text-[1rem] md:text-[0.9rem] lg:text-[1rem] "
              to="https://www.safaricom.co.ke/personal/m-pesa"
              target="_blank"
            >
              Mpesa
            </Link>
          </div>
        </div>
        <div className="skoolpesa text-center mr-[2rem] xxs:mt-3 xxs:mb-[1rem] xxs:text-center xsm:mt-[1rem] xsm:text-center xxs:mx-auto xsm:mx-auto sm:text-center md:text-center lg:text-center xl:text-center 2xl:text-center md:mr-0 lg:mr-0 xl:mr-0 2xl:mr-0">
          <img
            src={pesa}
            alt="Skool Pesa"
            className="w-[23rem] mx-auto mb-[1.4rem] xxs:w-[9rem] xxs:mb-1 xsm:w-[12rem] sm:w-[15rem] md:w-[11rem] lg:w-[14rem] xl:w-[14rem] 2xl:w-[14rem]"
          ></img>
          <p className="text-[2rem] font-semibold mb-[1.4rem] xxs:mb-1 xxs:text-[1.1rem] xsm:text-[1.3rem] sm:text-[1.5rem] md:text-[1.1rem] lg:text-[1.4rem] xl:text-[1.4rem] 2xl:text-[1.4rem]  ">
            Download the app today!
          </p>
          <div className="flex xxs:justify-center xxs:mx-auto">
            <Link
              className="cursor-pointer hover:text-[#162C4A] text-[1.3rem] xxs:text-[0.8rem] xsm:text-[1rem] md:text-[0.9rem] lg:text-[1rem] "
              to="https://apps.apple.com/ke/app/skool-pesa/id6446444889"
              target="_blank"
            >
              <img
                src={iosImage}
                alt="ios"
                className="w-[15rem] mr-[1rem] xxs:w-[7rem] xsm:w-[9rem] xxs:mr-2 xsm:mr-3 sm:w-[11rem] md:w-[8rem] lg:w-[10rem] xl:w-[10rem] 2xl:w-[11rem]  "
              />
            </Link>
            <Link
              className="cursor-pointer hover:text-[#162C4A] text-[1.3rem] xxs:text-[0.8rem] xsm:text-[1rem] md:text-[0.9rem] lg:text-[1rem] "
              to="https://play.google.com/store/apps/details?id=com.app.skool_pesa"
              target="_blank"
            >
              <img
                src={androidImage}
                alt="android"
                className="w-[15rem] xxs:w-[7rem] xsm:w-[9rem] xxs:ml-2 xsm:ml-3 sm:w-[11rem] md:w-[8rem] lg:w-[10rem] xl:w-[10rem] 2xl:w-[11rem] "
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="policy flex justify-between mt-[3rem] xxs:block xxs:mt-1 xxs:justify-center xxs:text-center xxs:items-center xsm:block xsm:mt-[1rem] xsm:justify-center xsm:text-center xsm:items-center md:flex md:justify-between lg:flex xl:flex 2xl:flex lg:justify-between xl:justify-between 2xl:justify-between lg:mt-9 xl:mt-9 2xl:mt-9">
        <div
          className="terms md:flex lg:flex xl:flex 2xl:flex lg:text-start"
          id="conditions"
        >
          <div className="flex xxs:flex xsm:flex xxs:justify-center xsm:justify-center xxs:pb-[0.2rem] xsm:pb-[0.2rem] ">
            <Link
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
              to="/privacy-policy"
              className="cursor-pointer hover:text-[#162C4A] text-[1.3rem] xxs:text-[0.9rem] xsm:text-[1.1rem] sm:text-[1.1rem] md:text-[1rem]  lg:text-[1.2rem] xl:text-[1.2rem] 2xl:text-[1.2rem]"
            >
              Privacy Policy
            </Link>
            <Link
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
              to="/terms-and-conditions"
              className="cursor-pointer hover:text-[#162C4A] text-[1.3rem] mx-[1.5rem] xxs:text-[0.9rem] xsm:text-[1.1rem] xxs:mr-0 xsm:mr-0 sm:text-[1.1rem] md:text-[1rem] lg:text-[1.2rem] xl:text-[1.2rem] 2xl:text-[1.2rem]"
            >
              Terms & Conditions
            </Link>
          </div>
          <Link
            className="cursor-pointer hover:text-[#162C4A] text-[1.3rem] xxs:text-[0.9rem] xsm:text-[1.1rem] xxs:mt-0 xxs:m-auto xxs:text-center xxs:justify-center xxs:items-center xsm:mt-0 xsm:m-auto xsm:text-center xsm:justify-center xsm:items-center xxs:pb-[1rem] xsm:pb-[1.3rem] sm:text-[1.1rem] md:text-[1rem] md:ml-4 lg:text-[1.3rem] xl:text-[1.2rem] 2xl:text-[1.2rem] lg:ml-4 xl:ml-4 2xl:ml-4 "
            id="cookies"
          >
            Cookies
          </Link>
        </div>
        <p className="text-[1.3rem] xxs:text-[0.9rem] xsm:text-[1.1rem] xxs:pb-[1rem] xsm:pb-[1.3rem] md:text-[1rem] lg:text-[1.2rem]">
          <span>&#169;</span> SkoolPesa Inc, All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
