import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import ServicesPage from "../pages/ServicesPage";
import WhySkoolPesaPage from "../pages/WhySkoolPesaPage";
import ContactPage from "../pages/ContactPage";
import FaqsPage from "../pages/FaqsPage";
import SignupPage from "../pages/SignupPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import TermsAndConditionsPage from "../pages/TermsAndConditionsPage";
import MyAccount from "../pages/MyAccount";

function Routings() {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/about-us" element={<AboutPage />} />
      <Route exact path="/services" element={<ServicesPage />} />
      <Route exact path="/why-skool-pesa" element={<WhySkoolPesaPage />} />
      <Route exact path="/faqs" element={<FaqsPage />} />
      <Route exact path="/contact" element={<ContactPage />} />
      <Route exact path="/sign-up" element={<SignupPage />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route
        exact
        path="/terms-and-conditions"
        element={<TermsAndConditionsPage />}
      />
      <Route exact path="/my-account" element={<MyAccount />} />
    </Routes>
  );
}

export default Routings;
