import React from "react";
import Faqs from "../components/faqs/Faqs";
import Footer from "../components/footer/Footer";

function FaqsPage() {
  return (
    <div>
      <Faqs />
      <Footer />
    </div>
  );
}

export default FaqsPage;
