import React from "react";
import Footer from "../components/footer/Footer";
import Home from "../components/home/Home";

function HomePage() {
  return (
    <div>
      <Home />
      <Footer />
    </div>
  );
}

export default HomePage;
