import React from "react";
import Footer from "../components/footer/Footer";
import WhySkoolPesa from "../components/skoolpesa/WhySkoolPesa";

function WhySkoolPesaPage() {
  return (
    <div>
      <WhySkoolPesa />
      <Footer />
    </div>
  );
}

export default WhySkoolPesaPage;
