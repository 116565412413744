import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/footer/Footer";
import PrivacyPolicy from "../components/policy/PrivacyPolicy";
import logo from "../images/logo-300x51.png";

function PrivacyPolicyPage() {
  const [toggle, setToggle] = useState(false);

  const handleClick = () => {
    setToggle(!toggle);
  };

  return (
    <div>
      <div className="homepage bg-[#162C4A] py-10 px-32 h-[70px] xxs:py-4 xxs:px-6 xxs:h-[60px] xsm:h-[70px]  xsm:py-6 xsm:px-8 md:py-3 lg:pt-6 md:h-[50px]  lg:px-16 xl:px-28 lg:h-[76px] xl:h-[76px] 2xl:h-[86px]">
        <div className="sectOne flex xxs:justify-between">
          {!toggle ? (
            <Link to="/">
              <img
                src={logo}
                alt="skoolpesa logo"
                className="cursor-pointer text-[1.3rem] xxs:w-[10rem] md:w-[9rem] lg:w-[11rem] xl:w-[12rem] 2xl:w-[13rem] "
              ></img>
            </Link>
          ) : (
            <></>
          )}
          <div className="navbar flex justify-between ml-[5rem] text-[1.1rem] w-full xxs:hidden md:flex md:text-[0.4rem] md:ml-6 lg:ml-[2rem] lg:text-[0.5rem] xl:text-[0.7rem] 2xl:text-[1rem] ">
            <div className="navigation text-white mt-[1rem] md:flex">
              <Link
                to="/"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Home
              </Link>
              <Link
                to="/about-us"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                About us
              </Link>
              <Link
                to="/services"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Services
              </Link>
              <Link
                to="/why-skool-pesa"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Why Skool Pesa
              </Link>
              <Link
                to="/faqs"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                FAQs
              </Link>
              <Link
                to="/contact"
                className="mx-5 hover:text-[#162C4A] md:mx-2 lg:mx-3"
              >
                Contact Us
              </Link>
            </div>
            <Link
              to="/sign-up"
              className="my-auto text-white text-[1rem] bg-[#162C4A] rounded-lg px-9 py-4 hover:bg-white hover:text-[#162C4A] hover:border-1 hover:border-[#162C4A] md:text-[0.4rem] md:px-3 md:py-1 md:rounded-[3px] md:my-auto lg:text-[0.4rem] xl:text-[0.7rem] 2xl:text-[1rem] 2xl:px-6 2xl:py-2 2xl:rounded-lg"
            >
              Sign Up Now
            </Link>
          </div>

          <div className="flex">
            {toggle ? (
              <div className="z-50 md:hidden xl:hidden 2xl:hidden bg-[#162C4A] xxs:w-[15rem] xsm:w-[17rem] sm:w-[19rem] grid sm:p-3 xxs:h-[24rem] sm:h-[24rem] xxs:ml-[-1rem] xsm:ml-[rem] sm:ml-[rem] xxs:text-white sm:text-white xxs:pb-24 xxs:p-2 sm:pb-28 ">
                <Link to="/" className="hover:text-[#162C4A]">
                  Home
                </Link>
                <Link to="/about-us" className="hover:text-[#162C4A]">
                  About us
                </Link>
                <Link to="/services" className="hover:text-[#162C4A]">
                  Services
                </Link>
                <Link to="/why-skool-pesa" className="hover:text-[#162C4A]">
                  Why Skool Pesa
                </Link>
                <Link to="/faqs" className="hover:text-[#162C4A]">
                  FAQs
                </Link>
                <Link to="/contact" className="hover:text-[#162C4A]">
                  Contact Us
                </Link>
                <Link to="/sign-up" className="hover:text-[#162C4A]">
                  Signup
                </Link>
                {/* <input placeholder="Search" className="rounded-[20rem] px-3" /> */}
              </div>
            ) : (
              <></>
            )}
          </div>
          {!toggle ? (
            <i
              className="fa fa-bars text-[#162C4A] border border-blue-400 py-1 px-1 rounded-sm bg-blue-400 xxs:visible xxs:text-white md:hidden xl:hidden 2xl:hidden h-6"
              onClick={handleClick}
            ></i>
          ) : (
            <i
              className="fa fa-times text-[#162C4A] border border-[#162C4A] py-1 px-2 rounded-sm bg-[#162C4A] xxs:visible xxs:text-white md:hidden xl:hidden 2xl:hidden h-6"
              onClick={handleClick}
            ></i>
          )}
        </div>
      </div>
      <PrivacyPolicy />
      <Footer />
    </div>
  );
}

export default PrivacyPolicyPage;
