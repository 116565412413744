import React, { useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

const formValidationSchema = Yup.object().shape({
  institution: Yup.string().required("Institution is required"),
  phone: Yup.string().required("Phone number is required"),
  addressLine1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  country: Yup.string()
    .oneOf(["Kenya", "Tanzania"])
    .required("Country is required"),
  province: Yup.string().required("Province is required"),
  postalCode: Yup.string().required("Postal code is required"),
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required"),
});

const formInitialValues = {
  institution: "",
  phone: "",
  addressLine1: "",
  city: "",
  province: "",
  postalCode: "",
  country: "",
  email: "",
  comments: "",
};

function SignupForm() {
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const contactType = "place";

  const handleSubmit = async (values) => {
    if (values.country === "Kenya") {
      try {
        const response = await fetch(
          "https://run.outfieldapp.com/api/v2/contacts",
          {
            method: "POST",
            headers: {
              "x-auth-token": "caosL91tmCzXsmyKHGYy",
              "x-user-email": "diana@skoolpesa.com",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              contact: {
                contact_type: contactType,
                name: values.institution,
                phones: [
                  { label: "mobile", value: values.phone, preferred: null },
                ],
                addresses: [
                  {
                    street1: values.addressLine1,
                    city: values.city,
                    region: values.province,
                    postal_code: values.postalCode,
                  },
                ],
                emails: [{ label: null, value: values.email, preferred: null }],
              },
            }),
          }
        );

        const data = await response.json();

        if (data.contact.is_active === true) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            navigate("/");
          }, 5000);
        } else {
          alert(data.error);
        }
      } catch (error) {
        console.error(error);
      } finally {
        // setSubmitting(false);
      }
    } else if (values.country === "Tanzania") {
      try {
        const response = await fetch(
          "https://run.outfieldapp.com/api/v2/contacts",
          {
            method: "POST",
            headers: {
              "x-auth-token": "iM14LvMD8WSRpsrqvLDb",
              "x-user-email": "emmanuel@skoolpesa.com",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              contact: {
                contact_type: contactType,
                name: values.institution,
                phones: [
                  { label: "mobile", value: values.phone, preferred: null },
                ],
                addresses: [
                  {
                    street1: values.addressLine1,
                    city: values.city,
                    region: values.province,
                    postal_code: values.postalCode,
                  },
                ],
                emails: [{ label: null, value: values.email, preferred: null }],
              },
            }),
          }
        );

        const data = await response.json();

        if (data.contact.is_active === true) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            navigate("/");
          }, 5000);
        }
      } catch (error) {
        console.error(error);
      } finally {
        // setSubmitting(false);
      }
    }
  };
  return (
    <div className="border border-gray-200 rounded-lg bg-gray-200 w-[40%] mx-auto xxs:w-[90%] sm:w-[80%] md:w-[70%]  lg:w-[60%] xl:w-[50%]  2xl:w-[40%] ">
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form className="w-[90%] mx-auto xxs:w-[90%]">
            <div className="my-[1rem] mt-[2rem] xxs:mt-5">
              <label htmlFor="institution" className="font-semibold">
                Name of Educational Institution:
              </label>{" "}
              <i
                class="fa fa-asterisk fa-2xs"
                style={{ color: "red" }}
                aria-hidden="true"
              ></i>
              <Field
                name="institution"
                type="text"
                className="w-full p-1 rounded-[3px] outline-blue-500"
              />
              <p className="text-red-600 text-[14px]">
                <ErrorMessage name="institution" />
              </p>
            </div>
            {/* <div className="my-[1rem]">
              <label htmlFor="numberOfStudents" className="font-semibold">
                Number of Students:
              </label>{" "}
              <Field
                name="numberOfStudents"
                type="number"
                className="w-full rounded-[3px] p-1 outline-blue-500"
              />
              <p className="text-red-600 text-[13px]">
                <ErrorMessage
                  name="numberOfStudents"
                  className="text-red-500"
                />
              </p>
            </div> */}

            <div className="my-[1rem]">
              <label htmlFor="phone" className="font-semibold">
                Phone:
              </label>{" "}
              <i
                class="fa fa-asterisk fa-2xs"
                style={{ color: "red" }}
                aria-hidden="true"
              ></i>
              <Field
                name="phone"
                type="text"
                placeholder="+254712345678 0r +255712345678"
                className="w-full rounded-[3px] p-1 outline-blue-500"
              />
              <p className="text-red-600 text-[13px]">
                <ErrorMessage name="phone" />
              </p>
            </div>

            <div className="my-[1rem]">
              <label htmlFor="addressLine1" className="font-semibold">
                Address:
              </label>{" "}
              <i
                class="fa fa-asterisk fa-2xs"
                style={{ color: "red" }}
                aria-hidden="true"
              ></i>
              <Field
                name="addressLine1"
                type="text"
                className="w-full rounded-[3px] p-1 outline-blue-500"
              />
              <p className="text-red-600 text-[13px]">
                <ErrorMessage name="addressLine1" />
              </p>
            </div>

            {/* <div className="my-[1rem]">
              <label htmlFor="addressLine2" className="font-semibold">
                Address Line 2:
              </label>
              <Field
                name="addressLine2"
                type="text"
                className="w-full rounded-[3px] p-1 outline-blue-500"
              />
              <p className="text-red-600 text-[13px]">
                <ErrorMessage name="addressLine2" />
              </p>
            </div> */}

            <div className="my-[1rem]">
              <label htmlFor="city" className="font-semibold">
                City:
              </label>{" "}
              <i
                class="fa fa-asterisk fa-2xs"
                style={{ color: "red" }}
                aria-hidden="true"
              ></i>
              <Field
                name="city"
                type="text"
                className="w-full rounded-[3px] p-1 outline-blue-500"
              />
              <p className="text-red-600 text-[13px]">
                <ErrorMessage name="city" />
              </p>
            </div>

            <div className="my-[1rem]">
              <label htmlFor="province" className="font-semibold">
                State/Province/Reqion:
              </label>{" "}
              <i
                class="fa fa-asterisk fa-2xs"
                style={{ color: "red" }}
                aria-hidden="true"
              ></i>
              <Field
                name="province"
                type="text"
                className="w-full rounded-[3px] p-1 outline-blue-500"
              />
              <p className="text-red-600 text-[13px]">
                <ErrorMessage name="province" />
              </p>
            </div>

            <div className="my-[1rem]">
              <label htmlFor="postalCode" className="font-semibold">
                ZIP/Postal Code:
              </label>{" "}
              <i
                class="fa fa-asterisk fa-2xs"
                style={{ color: "red" }}
                aria-hidden="true"
              ></i>
              <Field
                name="postalCode"
                type="text"
                className="w-full rounded-[3px] p-1 outline-blue-500"
              />
              <p className="text-red-600 text-[13px]">
                <ErrorMessage name="postalCode" className="w-[95%]" />
              </p>
            </div>

            <div className="my-[1rem]">
              <label htmlFor="country" className="font-semibold">
                Country:
              </label>{" "}
              <i
                class="fa fa-asterisk fa-2xs"
                style={{ color: "red" }}
                aria-hidden="true"
              ></i>
              <Field
                as="select"
                name="country"
                type="text"
                className="w-full rounded-[3px] p-1 outline-blue-500 bg-white"
              >
                {" "}
                <option value="">Please select your country</option>
                <option value="Kenya">Kenya</option>
                <option value="Tanzania">Tanzania</option>
              </Field>
              <p className="text-red-600 text-[13px]">
                <ErrorMessage name="country" className="w-[95%]" />
              </p>
            </div>

            {/* <div className="my-[1rem] w-[100%]">
              <label htmlFor="firstName" className="font-semibold">
                Name of Head of Instituion:
              </label>{" "}
              <i
                class="fa fa-asterisk fa-2xs"
                style={{ color: "red" }}
                aria-hidden="true"
              ></i>
              <div className="flex w-full xxs:block lg:flex lg:justify-between">
                <div>
                  <Field
                    name="firstName"
                    type="text"
                    className="mr-3 w-[20.5rem] rounded-[3px] p-1 outline-blue-500 xxs:w-[100%] lg:w-[16rem] xl:w-[17rem]    "
                  />
                  <p>First</p>
                  <p className="text-red-600 text-[13px]">
                    <ErrorMessage name="firstName" />
                  </p>
                </div>
                <div>
                  <Field
                    name="lastName"
                    type="text"
                    className="w-[20.5rem] rounded-[3px] p-1 outline-blue-500 xxs:w-[100%] lg:w-[16rem] xl:w-[17rem]     "
                  />
                  <p>Last</p>
                  <p className="text-red-600 text-[13px]">
                    <ErrorMessage name="lastName" />
                  </p>
                </div>
              </div>
            </div> */}

            <div className="my-[1rem]">
              <label htmlFor="email" className="font-semibold">
                Email
              </label>{" "}
              <i
                class="fa fa-asterisk fa-2xs"
                style={{ color: "red" }}
                aria-hidden="true"
              ></i>
              <Field
                name="email"
                type="email"
                className="w-full rounded-[3px] p-1 outline-blue-500"
              />
              <p className="text-red-600 text-[13px]">
                <ErrorMessage name="email" />
              </p>
            </div>

            <div className="my-[1rem]">
              <label htmlFor="comments" className="font-semibold">
                Additional Comments
              </label>
              <textarea
                name="comments"
                type="text"
                rows="6"
                className="w-full h-24 rounded-[3px] p-1 outline-blue-500"
              />
              <p className="text-red-600 text-[13px]">
                <ErrorMessage name="comments" />
              </p>
            </div>

            <button
              type="submit"
              className="border border-[#162C4A] py-1 px-2 rounded-[3px] my-2 text-white bg-[#162C4A]"
            >
              Submit Here
            </button>
            {success && (
              <div className="text-green-500">
                Your request has been received. Our agent will reach out
                shortly.
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SignupForm;
